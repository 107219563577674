import React, { useContext, useEffect, useState } from "react";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import * as yup from "yup";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthContainer from "../../components/AuthContainer/AuthContainer";
import { authActions, cssClasses } from "../../constants";
import instance from "../../apis/axios";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";

const ChangePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location?.state?.email;
  const { auth } = cssClasses;
  const [loading, setloading] = useState(false);
  const { authDispatch } = useContext(AuthContext);

  const resetPassValidationSchema = yup.object({
    oldPassword: yup
      .string("Please enter password.")
      .min(12, "password should be more than 12 characters")
      .required("Password is required"),
    newPassword: yup
      .string("Please enter password.")
      .min(12, "password should be more than 12 characters")
      .required("Password is required"),
    passwordConfirmation: yup
      .string("Please enter confirm password.")
      .oneOf([yup.ref("newPassword"), null], "Passwords must match"),
  });

  const resetPassFormik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: resetPassValidationSchema,
    onSubmit: (values) => {
      setloading(true);
      if (values.newPassword === values.passwordConfirmation)
        instance
          .post("/auth/change-password", {
            email: email,
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          })
          .then(({ data }) => {
            if (data.message && data.status) {
              authDispatch({ type: authActions.PASSWORDCHANGED });
              notify(
                data.message || "Password has been successfully reset.",
                true
              );
              navigate("/home");
              setloading(false);
            }
          })
          .catch((err) => {
            setloading(false);
            notify(err?.response?.data?.message || err);
          });
    },
  });

  useEffect(() => {
    if (!email) {
      navigate("/login");
    }
  }, [navigate, email]);

  return (
    <AuthContainer>
      <div className={auth.formTitleLeft}>
        <Typography
          color={(theme) => theme.palette.primary.main}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon fontSize="large" />
        </Typography>
        <div className={auth.formHeading}>
          <Typography
            variant="h4"
            color={(theme) => theme.palette.primary.main}
          >
            CHANGE PASSWORD
          </Typography>
        </div>
      </div>
      <form className={auth.form} onSubmit={resetPassFormik.handleSubmit}>
        <TextField
          placeholder="Old Password"
          fullWidth
          sx={{ mt: 3 }}
          InputProps={{
            autoComplete: "off",
            className: auth.formInput,
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="oldPassword"
          name="oldPassword"
          type="password"
          value={resetPassFormik.values.oldPassword}
          onChange={resetPassFormik.handleChange}
          onBlur={resetPassFormik.handleBlur}
          error={
            resetPassFormik.touched.oldPassword &&
            Boolean(resetPassFormik.errors.oldPassword)
          }
          helperText={
            (resetPassFormik.touched.oldPassword &&
              resetPassFormik.errors.oldPassword) ||
            " "
          }
        />
        <TextField
          placeholder="New Password"
          fullWidth
          sx={{ mt: 3 }}
          InputProps={{
            autoComplete: "off",
            className: auth.formInput,
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="newPassword"
          name="newPassword"
          type="password"
          value={resetPassFormik.values.newPassword}
          onChange={resetPassFormik.handleChange}
          onBlur={resetPassFormik.handleBlur}
          error={
            resetPassFormik.touched.newPassword &&
            Boolean(resetPassFormik.errors.newPassword)
          }
          helperText={
            (resetPassFormik.touched.newPassword &&
              resetPassFormik.errors.newPassword) ||
            " "
          }
        />
        <TextField
          placeholder="Confirm Password"
          fullWidth
          sx={{ mt: 3 }}
          InputProps={{
            autoComplete: "off",
            className: auth.formInput,
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="passwordConfirmation"
          name="passwordConfirmation"
          type="password"
          value={resetPassFormik.values.passwordConfirmation}
          onChange={resetPassFormik.handleChange}
          onBlur={resetPassFormik.handleBlur}
          error={
            resetPassFormik.touched.passwordConfirmation &&
            Boolean(resetPassFormik.errors.passwordConfirmation)
          }
          helperText={
            (resetPassFormik.touched.passwordConfirmation &&
              resetPassFormik.errors.passwordConfirmation) ||
            " "
          }
        />
        <Button
          fullWidth
          className={auth.formButton}
          variant="contained"
          sx={{ mt: 3 }}
          type="submit"
          disabled={loading}
        >
          Submit
        </Button>
      </form>
    </AuthContainer>
  );
};

export default ChangePassword;
