import { Box, Button, Typography } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "../Dashboard/Dashboard.css";

const SuccessPayment = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          display: "block",
          flexDirection: "column",
          width: { xl: "50%", lg: "50%", xs: "100%" },
          minWidth: { xl: "50%", lg: "50%", xs: "auto" },
          padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
          overflow: { xl: "unset", lg: "unset", xs: "auto" },
          mt: { xs: 0, xl: 2, lg: 2 },
          flexWrap: "wrap",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography
          color={(theme) => theme.palette.primary.main}
          onClick={() => navigate("/financial-details")}
        >
          <ArrowBackIcon fontSize="large" />
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          color={(theme) => theme.palette.primary.main}
        >
          Payment Status
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          width="80%"
          justifyContent="flex-start"
          alignItems="center"
          sx={{ mt: 5 }}
        >
          <div>
            <CheckCircleIcon
              color="success"
              sx={{
                height: 90,
                width: 90,
              }}
            />
          </div>
          <Box sx={{ ml: 2 }}>
            <Typography fontSize={20} fontWeight={600}>
              Payment Success
            </Typography>
            <Typography
              fontSize={13}
              color={(theme) => theme.palette.secondary.main}
            >
              Your payment was successful and you can check your payment entry
              in financial details.
            </Typography>
          </Box>
        </Box>
        {/* <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          border={3}
          borderColor="#f7f7f7"
          sx={{
            mt: 2,
            padding: {
              xs: "15px",
              xl: "35px 0px 35px 95px",
            },
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            padding={1}
            sx={{
              width: { xs: "auto", xl: "35%", lg: "35%" },
              flex: "1 1 auto",
            }}
          >
            <Typography color={(theme) => theme.palette.secondary.main}>
              Payment
            </Typography>
            <Typography color={(theme) => theme.palette.secondary.main}>
              £120.00
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: { xs: "auto", xl: "35%", lg: "35%" },
              flex: "1 1 auto",
            }}
            padding={1}
          >
            <Typography color={(theme) => theme.palette.secondary.main}>
              Tax & Fees
            </Typography>
            <Typography color={(theme) => theme.palette.secondary.main}>
              £6.00
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "auto", xl: "35%", lg: "35%" },
              flex: "1 1 auto",
            }}
            padding={1}
          >
            <hr />
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: { xs: "auto", xl: "35%", lg: "35%" },
              flex: "1 1 auto",
            }}
            padding={1}
          >
            <Typography color={(theme) => theme.palette.secondary.main}>
              Total
            </Typography>
            <Typography color={(theme) => theme.palette.primary.main}>
              £126.00 (PAID)
            </Typography>
          </Box>
        </Box> */}
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            mt: 3,
            width: { xs: "100%", sm: "50%" },
          }}
        >
          <Link style={{ display: "flex" }} to="/financial-details">
            <Button
              className="form__button"
              variant="contained"
              sx={{
                flex: "1",
              }}
            >
              Financial Details
            </Button>
          </Link>
          {/* <Link
            style={{ flex: "1 1 auto", display: "flex" }}
            to="/make-payment"
          >
            <Button
              className="form__button"
              variant="contained"
              sx={{ flex: "1 1 auto" }}
            >
              Make Payment
            </Button>
          </Link> */}
        </Box>
      </Box>
    </>
  );
};

export default SuccessPayment;
