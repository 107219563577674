import axios from "axios";
import { requests } from "../constants";
import { getUserToken } from "../utils/userToken";

export const fetchInvoiceTerms = async (Code) => {
  const invoiceTerms = await axios.post(requests.GetDTInvoiceTerms, {
    accessToken: getUserToken(),
    DebtorTransactionCode: Code,
  });

  return invoiceTerms?.data?.d?.Terms;
};

const fetchDTInvoiceTerms = (transactions) => {
  var tt = {};
  if (transactions.length) {
    const promieses = transactions.map(async (item) => {
      const invoiceTerms = await axios.post(requests.GetDTInvoiceTerms, {
        accessToken: getUserToken(),
        DebtorTransactionCode: item?.Code,
      });
      if (Object.keys(invoiceTerms?.data).length) {
        tt = { ...tt, [item?.Code]: invoiceTerms?.data?.d?.Terms };
      }
    });
    return Promise.all(promieses)
      .then(() => tt)
      .catch((err) => err);
  }
};
export default fetchDTInvoiceTerms;
