import React, { useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthContainer from "../../components/AuthContainer/AuthContainer";
import { cssClasses, requests } from "../../constants";
import instance from "../../apis/axios";
import { routesForLogos } from "../../utils/routesForLogos";
import { notify } from "../../components/ToastContainer/ToastContainer";
import axios from "axios";
import { getUserToken } from "../../utils/userToken";

const RegistrationPage = () => {
  const [queryParams] = useSearchParams();
  const referenceId = queryParams.get("cid");
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const [loading, setloading] = useState(false);
  const [msg, setmsg] = useState(null);
  const [error, seterror] = useState("");

  const validationSchema = yup.object({
    email: yup
      .string("Please enter email")
      .email("Please enter valid email.")
      .required("Email is required"),
    postCode: yup
      .string("Please enter Post code")
      .required(
        "Postcode is required and please enter a space in your postcode"
      ),
    password: yup
      .string("Please enter password")
      .min(12, "Password should be more than 12 characters")
      .required("Password is required"),
    passwordConfirmation: yup
      .string("Please enter confirm password.")
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      postCode: "",
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setloading(true);
      instance
        .post(requests.register, {
          referenceId,
          email: values.email,
          postCode: values.postCode,
          password: values.password,
          domain: routesForLogos.getCurrentURLConfig("domain"),
        })
        .then(async ({ data }) => {
          if (data) {
            try {
              const invoiceTerms = await axios.post(
                requests.UpdateDebtor,

                {
                  Debtor: {
                    DebtorCode: referenceId,
                    CustomFields: [
                      {
                        index: 1,
                        CustomFieldLabel: "custom value 1",
                        CustomFieldValue: "Yes",
                      },
                    ],
                  },

                  accessToken: getUserToken(),
                }
              );

              console.log("invoiceTerms", invoiceTerms);
            } catch (error) {
              console.error("Error:", error);
            }

            setloading(false);

            return setmsg(
              "Please verify your email address by clicking link provided in the mail."
            );
          }
        })
        .catch((err) => {
          setloading(false);
          seterror(err?.response?.data?.message || err);
        });
    },
  });

  const checkCustomerEligibility = useCallback(
    async (referenceId) => {
      setloading(true);
      try {
        const isEligible = await instance.post(
          requests.isCustomerEligibleForRegistration,
          { referenceId }
        );
        if (!isEligible?.data?.message && !isEligible?.data?.status) {
          navigate("/login");
        }
        setloading(false);
      } catch (err) {
        if (err?.response?.data?.message)
          notify(err?.response?.data?.message, false);
        navigate("/login");
        setloading(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (referenceId) checkCustomerEligibility(referenceId);
  }, [referenceId, checkCustomerEligibility]);

  useEffect(() => {
    if (!referenceId) navigate("/login");
  }, [referenceId, navigate]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userAuth"));
    if (userData) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <AuthContainer>
      {!msg && (
        <div className={auth.formTitleLeft}>
          <Typography
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate("/login")}
            sx={{ cursor: "pointer" }}
          >
            <ArrowBackIcon fontSize="large" />
          </Typography>
          <div className={auth.formHeading}>
            <Typography
              variant="h4"
              color={(theme) => theme.palette.primary.main}
            >
              REGISTRATION
            </Typography>
          </div>
        </div>
      )}
      {loading ? (
        <div className={auth.form}>
          <CircularProgress />
        </div>
      ) : msg ? (
        <div className={auth.formTitleLeft}>
          <Typography
            variant="h6"
            color={(theme) => theme.palette.secondary.main}
          >
            {msg}
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: 3, cursor: "pointer" }}
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate("/login")}
          >
            Go to Login
          </Typography>
        </div>
      ) : (
        <form
          className={auth.form}
          style={{ marginTop: "22px" }}
          onSubmit={formik.handleSubmit}
        >
          <TextField
            placeholder="Email"
            variant="outlined"
            fullWidth
            sx={{ mt: 3 }}
            InputProps={{
              autoComplete: "off",
              className: auth.formInput,
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutlinedIcon />
                </InputAdornment>
              ),
            }}
            id="email"
            name="email"
            type="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || " "}
          />
          <TextField
            placeholder="Post Code"
            variant="outlined"
            fullWidth
            sx={{ mt: 3 }}
            InputProps={{
              autoComplete: "off",
              className: auth.formInput,
              startAdornment: (
                <InputAdornment position="start">
                  <LocationCityIcon />
                </InputAdornment>
              ),
            }}
            id="postCode"
            name="postCode"
            type="text"
            value={formik.values.postCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.postCode && Boolean(formik.errors.postCode)}
            helperText={
              (formik.touched.postCode && formik.errors.postCode) || " "
            }
          />
          <TextField
            placeholder="Create Password"
            fullWidth
            sx={{ mt: 3 }}
            InputProps={{
              autoComplete: "off",
              className: auth.formInput,
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={
              (formik.touched.password && formik.errors.password) || " "
            }
          />
          <TextField
            placeholder="Confirm New Password"
            fullWidth
            sx={{ mt: 3 }}
            InputProps={{
              autoComplete: "off",
              className: auth.formInput,
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlinedIcon />
                </InputAdornment>
              ),
            }}
            id="passwordConfirmation"
            name="passwordConfirmation"
            type="password"
            value={formik.values.passwordConfirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.passwordConfirmation &&
              Boolean(formik.errors.passwordConfirmation)
            }
            helperText={
              (formik.touched.passwordConfirmation &&
                formik.errors.passwordConfirmation) ||
              " "
            }
          />
          <Button
            fullWidth
            className={auth.formButton}
            variant="contained"
            sx={{ mt: 3 }}
            type="submit"
            disabled={loading}
          >
            Proceed
          </Button>
          {error && (
            <Typography color="red" sx={{ mt: 1, maxWidth: "300px" }}>
              {error}
            </Typography>
          )}
        </form>
      )}
    </AuthContainer>
  );
};

export default RegistrationPage;
