import React from "react";
import { Box } from "@mui/system";

import background from "../../assets/background/Login-background.png";
import "./AuthenticationPages.css";

const AuthContainer = ({ children, isloginForm }) => {
  return (
    <div className="conatiner">
      <div className="sub__conatiner">
        <Box
          flex={1}
          sx={{
            display: { xs: "none", sm: "block", xl: "block" },
          }}
        >
          <img
            alt="background"
            className="sub__conatiner--background"
            src={background}
          />
        </Box>
        <div
          className={
            isloginForm ? "login__conatiner--form" : "sub__conatiner--form"
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthContainer;
