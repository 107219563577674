import { requests } from "../constants";
import instance from "./axios";

export const login = (email, password) => {
  return instance
    .post(requests.login, {
      email,
      password,
    })
    .then((response) => response)
    .catch((err) => {
      throw new Error(err?.response?.data?.message || err);
    });
};

export const loginThroughToken = (accessToken) => {
  return instance
    .post(requests.loginThroughToken, {
      accessToken,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const getNewAccessToken = (refreshToken) => {
  return instance
    .post(requests.getToken, {
      refreshToken,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};

export const braintreeCheckout = (nonce, amount) => {
  return instance
    .post(requests.braintreeCheckout, {
      paymentMethodNonce: nonce,
      amount,
    })
    .then((response) => response)
    .catch((err) => {
      if (err) {
        throw new Error(err?.response?.data?.message || err);
      }
    });
};
