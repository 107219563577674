import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function PayerConfirmationDialog({
  isOpen,
  hanldeOnClick,
  handleOnClick3rdPaty,
  handleOnClickCanel,
}) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Can you confirm you are the Cardholder?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <strong>Important Information </strong>
          <br />
          If you are not the cardholder, we will require you to provide the
          cardholders details prior to completing the 1p transaction. By adding
          this information, you are confirming the cardholder has given you
          authority to use these card details for all future payments.
          <br />
          <br />
          Please click on the YES button to continue with current details.
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button onClick={() => handleOnClick3rdPaty()}>
          No (Add Alternative Cardholder Information)
        </Button>
        <Box display="flex" gap="10px" alignItems="center">
          <Button onClick={() => handleOnClickCanel()}>Cancel</Button>
          <Button type="submit" onClick={() => hanldeOnClick()}>
            Yes
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default PayerConfirmationDialog;
