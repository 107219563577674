import { authActions } from "../../constants";

const AuthReducer = (state, action) => {
  switch (action.type) {
    case authActions.LOGIN:
      sessionStorage.setItem(
        "userAuth",
        JSON.stringify({
          ...action.payload,
        })
      );
      return {
        ...action.payload,
      };
    case authActions.LOGINTHROUGHTOKEN:
      return {
        ...action.payload,
      };

    case authActions.GOTTOKEN:
      const userData = JSON.parse(sessionStorage.getItem("userAuth"));
      sessionStorage.setItem(
        "userAuth",
        JSON.stringify({
          ...userData,
          accessToken: action.payload?.accessToken,
        })
      );
      return {
        ...userData,
        accessToken: action.payload?.accessToken,
      };

    case authActions.LOGOUT:
      sessionStorage.removeItem("userAuth");
      return {};

    case authActions.PASSWORDCHANGED:
      const userAuth = JSON.parse(sessionStorage.getItem("userAuth"));
      sessionStorage.setItem(
        "userAuth",
        JSON.stringify({
          ...userAuth,
        })
      );
      return {
        ...state,
      };

    default:
      return state;
  }
};

export default AuthReducer;
