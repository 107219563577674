import { debtorDetailsActions, debtStatus } from "../../constants";

const CustomerDetailsReducer = (state, action) => {
  switch (action.type) {
    case debtorDetailsActions.ADDDETAILS:
      const record = action.payload;
      // var initalBalance =
      //   record.Transactions[record.Transactions.length - 1]?.GrossAmount;
      // var reverseArr = Array.from(record.Transactions)
      //   .reverse()
      //   .map((record, i) => {
      //     if (i === 0) {
      //       record.Balance = record.GrossAmount;
      //     } else if (i > 0) {
      //       if (!record.Credit) {
      //         initalBalance += record.GrossAmount;
      //         record.Balance = initalBalance;
      //       } else {
      //         initalBalance -= record.GrossAmount;
      //         record.Balance = initalBalance;
      //       }
      //     }
      //     return record;
      //   });
      return {
        ...record,
        Transactions: record?.Transactions?.map((record) => {
          return {
            ...record,
            Status:
              Number(record?.InvoiceBalance) <= 0
                ? debtStatus.PAID
                : Number(record?.InvoiceBalance) < Number(record?.TotalAmount)
                ? debtStatus.PENDING
                : debtStatus.NOTPAID,
            RemainingAmount:
              Number(record?.InvoiceBalance) === Number(record?.TotalAmount)
                ? Number(record?.TotalAmount)
                : Number(record?.TotalAmount) - Number(record?.InvoiceBalance),
          };
        }),
      };

    case debtorDetailsActions.ADDTRANSACTIONTERMS:
      return {
        ...state,
        Transactions: state?.Transactions?.map((item) => {
          return {
            ...item,
            Status:
              Number(item?.InvoiceBalance) === 0
                ? debtStatus.PAID
                : Number(item?.InvoiceBalance) < Number(item?.TotalAmount)
                ? debtStatus.PENDING
                : debtStatus.NOTPAID,
            RemainingAmount:
              Number(item?.InvoiceBalance) === Number(item?.TotalAmount)
                ? Number(item?.TotalAmount)
                : Number(item?.TotalAmount) - Number(item?.InvoiceBalance),
          };
        }),
        ...action.payload,
      };
    case debtorDetailsActions.REMOVEDETAILS:
      return {};

    default:
      return state;
  }
};

export default CustomerDetailsReducer;
