import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import { useLocation, useNavigate } from "react-router-dom";

import "../Dashboard/Dashboard.css";
import { CustomerDetailsContext } from "../../stores/CustomerDetailsContext/CustomerDetailsContext";
import moment from "moment/moment";

const PaymentPlanConfirmation = () => {
  const { debtorDetailsState } = useContext(CustomerDetailsContext);
  const { state } = useLocation();
  const theme = useTheme();
  const paymentPlan = state?.paymentPlans;
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const navigate = useNavigate();

  useEffect(() => {
    if (!paymentPlan) {
      navigate("/home");
    }
  }, [navigate, paymentPlan]);

  return (
    <>
      <Box
        sx={{
          display: "block",
          flexDirection: "column",
          width: { xl: "50%", lg: "50%", xs: "100%" },
          minWidth: { xl: "50%", lg: "50%", xs: "auto" },
          padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
          overflow: { xl: "unset", lg: "unset", xs: "auto" },
          mt: { xs: 0, lg: 2, xl: 2 },
          mb: 2,
          flexWrap: "wrap",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography
          color={(theme) => theme.palette.primary.main}
          onClick={() => navigate("/home")}
        >
          <ArrowBackIcon fontSize="large" />
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          color={(theme) => theme.palette.primary.main}
        >
          PAYMENT PLAN CONFIRMATION
        </Typography>
        <Typography
          sx={{ mt: 2 }}
          fontWeight={600}
          variant="h6"
          color={(theme) => theme.palette.primary.main}
        >
          Thank you{" "}
          {debtorDetailsState?.Firstname
            ? (debtorDetailsState?.Title1 !== null
                ? debtorDetailsState?.Title1
                : "" || debtorDetailsState?.Title2 !== null
                ? debtorDetailsState?.Title2
                : "") +
              " " +
              debtorDetailsState?.Surname
            : ""}
          , your Payment Plan has been accepted
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Your payment plan has been successfully created and submitted to us.
          Your account status will be updated within 72 hours.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong>How we will collect your payments</strong>
        </Typography>
        <Typography>
          All payments will be collected via Continuous Payment Authority (CPA)
          on the instalment due dates for the amounts specified below. If a
          payment attempt is unsuccessful, we will notify you via email and will
          reattempt collection 2 days later.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          During your payment plan, should you wish to cancel your Continuous
          Payment Authority on your card, then you can do this by contacting our
          team on 01704 339 340. We are open Monday – Friday 9am – 5pm.
          Alternatively you will be able to cancel your Continuous Payment
          Authority by contacting your bank.
        </Typography>

        <Typography sx={{ mt: 1 }}>
          <strong>Important Information</strong>
        </Typography>
        <Typography>
          It is important that you maintain your agreed payment plan. If you
          believe you are going to miss a payment and require additional
          support, please call us on 01704 339 340. We are open Monday – Friday
          9am – 5pm. Alternatively, you can email us on &nbsp;
          <a href="mailto:CreditControlTeam@AcornInsure.com">
            CreditControlTeam@AcornInsure.com
          </a>
        </Typography>
        <Typography sx={{ mt: 1 }}>
          If you fail to make a payment, it is important that you contact us at
          your earliest opportunity. If we do not hear from you, we may take
          further action including referring your debt to our third-party
          solicitors.
        </Typography>

        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          sx={{ mt: 3 }}
        >
          <Box
            sx={{
              overflowX: "auto",
              mt: 2,
            }}
            // display="block"
            padding="2px"
          >
            {debtorDetailsState?.TotalDebt && (
              <table className="table" style={{ marginBottom: "20px" }}>
                <thead>
                  <tr style={{ background: theme.palette.primary.main }}>
                    {/* <th>Payment#</th> */}
                    <th>Instalment Due Date</th>
                    <th>Instalment Amount</th>
                    <th>Remaining Balance</th>
                    <th>Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentPlan?.map((data, index) =>
                    index === 0 ? (
                      <tr key={index} style={{ backgroundColor: "lightgreen" }}>
                        {/* <td>{index + 1}.</td> */}
                        <td>{moment(data?.date).format("Do MMMM YYYY")}</td>
                        <td>
                          £
                          {Number(data?.amount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          £
                          {Number(data?.balance)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          <DoneIcon sx={{ height: 20 }} />
                        </td>
                      </tr>
                    ) : (
                      <tr key={index}>
                        {/* <td>{index + 1}.</td> */}
                        <td>{moment(data?.date).format("Do MMMM YYYY")}</td>
                        <td>
                          £
                          {Number(data?.amount)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          £
                          {Number(data?.balance)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td></td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PaymentPlanConfirmation;
