import { Box, Typography, useTheme } from "@mui/material";
import React, { useContext, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DoneIcon from "@mui/icons-material/Done";
import { useNavigate } from "react-router-dom";

const NeedAdditionalSupport = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          display: "block",
          flexDirection: "column",
          width: { xl: "50%", lg: "50%", xs: "100%" },
          minWidth: { xl: "50%", lg: "50%", xs: "auto" },
          padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
          overflow: { xl: "unset", lg: "unset", xs: "auto" },
          mt: { xs: 0, lg: 2, xl: 2 },
          mb: 2,
          flexWrap: "wrap",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography
          color={(theme) => theme.palette.primary.main}
          onClick={() => navigate(-1)}
          //   style={{ cursor: "pointer" }}
        >
          <ArrowBackIcon fontSize="large" />
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          color={(theme) => theme.palette.primary.main}
        >
          Need Further Support?
        </Typography>

        <Typography sx={{ mt: 1 }}>
          We understand that many of our customers may be experiencing financial
          difficulties and other uncertainties. We are here to try to help our
          customers through difficult times.
        </Typography>
        <Typography sx={{ mt: 1 }}>
          If you require additional support, please call us on 01704 339 340. We
          are open Monday – Friday 9am – 5pm. Alternatively, you can email us on
          CreditControlTeam@AcornInsure.com
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Additionally, you can get free, non-judgmental, and independent help
          and advice from several organisations including:
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong> Money Helper’s Debt Advice </strong>
        </Typography>
        <Typography sx={{ mt: 1 }}>
          Locator Tool: www.moneyhelper.org.uk/debt-advice-locator or contact
          them on 0800 138 7777
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong> StepChange Debt Charity </strong>
        </Typography>
        <Typography sx={{ mt: 1 }}>
          For debt advice throughout the UK phone 0800 138 1111 or visit
          www.stepchange.org
        </Typography>
        <Typography sx={{ mt: 1 }}>
          <strong> Citizens Advice</strong>
        </Typography>
        <Typography sx={{ mt: 1 }}>
          For advice and information on debt and other topics, visit your local
          Citizens Advice or go to www.citizensadvice.org.uk
        </Typography>
      </Box>
    </>
  );
};

export default NeedAdditionalSupport;
