import React, { useCallback, useEffect, useState } from "react";
import "./Footer.css";
import { routesForFooter } from "./routesForFooter";

const Footer = () => {
  const [footerDeatils, setFooterDeatils] = useState(null);
  const currentUrl = window.location.host;

  const footerFunc = useCallback(() => {
    const footerData = routesForFooter[currentUrl];
    if (footerData) {
      setFooterDeatils(footerData);
    }
  }, [currentUrl]);

  useEffect(() => {
    if (currentUrl) {
      footerFunc();
    }
  }, [currentUrl, footerFunc]);

  return (
    <>
      <footer className="footer">
        <div className="footer-column">
          <h3>Company Information</h3>
          <p>{footerDeatils?.fullEntityDetail}</p>
        </div>
        <div className="footer-column">
          <h3>Registered Address</h3>
          <p>{footerDeatils?.registeredAddress}</p>
        </div>
        <div className="footer-column">
          <h3>Modern Slavery Statement</h3>
          <a
            href={footerDeatils?.statementLink}
            target="_blank"
            rel="statement"
          >
            {footerDeatils?.statement}
          </a>
        </div>
        <div className="footer-column">
          <h3>Gender Pay Gap Reporting</h3>
          <a
            href={footerDeatils?.genderPayLink}
            target="_blank"
            rel="genderPay"
          >
            {footerDeatils?.genderPay}
          </a>
        </div>
        <div className="footer-column">
          <h3>Contact Us</h3>
          <p>
            Call us: <a href="tel:+442033724627">{footerDeatils?.contactUs} </a>
            {footerDeatils?.contactUsDetail}
          </p>

          <p>
            Email us:
            <a href={footerDeatils?.contactUsEmail}>
              {footerDeatils?.contactUsEmail.replace("mailto:", "")}
            </a>
          </p>
        </div>
        <div className="footer-column">
          <h3>Privacy Policy </h3>
          <a
            href={footerDeatils?.privacyPolicyLink}
            target="_blank"
            rel="privacyPolicy"
          >
            {footerDeatils?.privacyPolicy}
          </a>
        </div>

        <div className="line"></div>
        <p className="copyRights">Copyright © 2023, All Right Reserved </p>
      </footer>
    </>
  );
};

export default Footer;
