import React from "react";
import { toast, ToastContainer as Toast } from "react-toastify";
import { alertConfig } from "../../constants";

const ToastContainer = () => {
  return (
    <Toast
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
    />
  );
};

export default ToastContainer;

export const notify = (msg, success) =>
  success
    ? toast?.success(msg?.toString(), alertConfig)
    : toast?.error(msg?.toString(), alertConfig);
