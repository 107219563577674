import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";

const Layout = () => {
  return (
    <div>
      <Header />
      <Box
        className="app__content"
        display="flex"
        sx={{
          minHeight: {
            xl: "calc(100vh - 70px)",
            xs: "calc(100vh - 60px)",
          },
          marginTop: { xl: "64px", xs: "66px" },
        }}
      >
        <Outlet />
      </Box>
      <Footer />
    </div>
  );
};

export default Layout;
