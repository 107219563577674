import { Avatar, Box, Button, Typography } from "@mui/material";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import BalanceCard from "../../components/BalanceCard/BalanceCard";

import { debtorDetailsActions, requests } from "../../constants";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { CustomerDetailsContext } from "../../stores/CustomerDetailsContext/CustomerDetailsContext";
import { getUserToken } from "../../utils/userToken";

import "./Dashboard.css";
import findTotalDebt, {
  findNextTransaction,
  findNextTransactionDate,
} from "../../utils/findTotalDebt";
import { notify } from "../../components/ToastContainer/ToastContainer";

const Dashboard = () => {
  const { debtorDetailsState, debtorDetailsDispatch } = useContext(
    CustomerDetailsContext
  );
  const { authState } = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setloading(true);
      if (authState?.user?.referenceId) {
        try {
          const debtorDetails = await axios.post(requests.GetDebtor, {
            DebtorReference: authState?.user?.referenceId,
            accessToken: getUserToken(),
          });
          if (debtorDetails?.data?.d?.EnquiryResult?.SuccessCode === 0) {
            const transactionsDetails = await axios.post(
              requests.GetDebtorsTransactionListJSON,
              {
                accessToken: getUserToken(),
                Status: "",
                SearchType: "DebtorId",
                SearchText: debtorDetails?.data?.d?.Debtor?.DebtorCode,
                startDate: "",
                endDate: "",
                recordStartNo: 1,
                recordEndNo: 10000,
              }
            );

            if (Object.keys(transactionsDetails).length) {
              const nextTermDate = await findNextTransactionDate(
                transactionsDetails.data.d.Records
              );

              debtorDetailsDispatch({
                type: debtorDetailsActions.ADDDETAILS,
                payload: {
                  ...debtorDetails.data.d.Debtor,
                  Transactions: transactionsDetails.data.d.Records,
                  TotalDebt: findTotalDebt(transactionsDetails.data.d.Records),
                  nextTermDate,
                },
              });

              return setloading(false);
            }
          } else {
            setError(debtorDetails?.data?.d?.EnquiryResult?.Message);
            return setloading(false);
          }
        } catch (err) {
          setloading(false);
          return notify(
            err?.response?.data?.Message ||
              err?.response?.data?.d?.EnquiryResult?.Message ||
              err
          );
        }
      }
    };

    fetchCustomerDetails();
  }, [authState?.user?.referenceId, debtorDetailsDispatch]);

  // console.log("debtorDetailsState", debtorDetailsState);

  return (
    <Box
      sx={{
        display: "flex",
      }}
      justifyContent="center"
      alignItems="center"
      flex="1"
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        {Object.keys(debtorDetailsState).length ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex="1"
            width="100%"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: { xs: "100%" },
                minWidth: { xs: "auto" },
                padding: { xs: "15px" },
              }}
              justifyContent="center"
              alignItems="flex-start"
              minWidth="800px"
            >
              <Typography
                variant="h5"
                fontWeight={600}
                color={(theme) => theme.palette.primary.main}
              >
                Your Dashboard
              </Typography>
              <Avatar sx={{ width: 70, height: 70, mt: 2 }} />
              <Typography
                variant="h5"
                fontWeight={600}
                color={(theme) => theme.palette.primary.main}
                sx={{
                  mt: 1,
                }}
              >
                Welcome{" "}
                {(debtorDetailsState?.Title1 !== null
                  ? debtorDetailsState?.Title1
                  : "" || debtorDetailsState?.Title2 !== null
                  ? debtorDetailsState?.Title2
                  : "") +
                  " " +
                  debtorDetailsState?.Firstname +
                  " " +
                  debtorDetailsState?.Surname}
              </Typography>
              {/* <Typography color={(theme) => theme.palette.secondary.main}>
                You can check and update your profile.
              </Typography> */}

              <BalanceCard
                availableBalance={debtorDetailsState?.TotalDebt}
                debtorReference={debtorDetailsState?.DebtorCode}
                loading={loading}
                bottomText={
                  debtorDetailsState?.nextTermDate
                    ? `Your next payment is due on ${debtorDetailsState?.nextTermDate}`
                    : null
                }
                fullHeightedCard={true}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{
                  mt: 3,
                  width: { xs: "100%", sm: "50%" },
                }}
              >
                <Link style={{ display: "flex" }} to="/financial-details">
                  <Button
                    className="form__button"
                    variant="contained"
                    sx={{
                      flex: "1",
                    }}
                  >
                    Manage your Account
                  </Button>
                </Link>
              </Box>
            </Box>
          </Box>
        ) : !loading && error ? (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flex="1"
            width="100%"
          >
            <Typography variant="h6">{error}</Typography>
          </Box>
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default Dashboard;
