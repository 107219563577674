import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "../Dashboard/Dashboard.css";
import moment from "moment";
import axios from "axios";
import { requests } from "../../constants";
import { routesForLogos } from "../../utils/routesForLogos";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";
import PayerConfirmationDialog from "../../components/PayerConfirmationDialog/PayerConfirmationDialog";
import { fetchInvoiceTerms } from "../../apis/fetchDTInvoiceTerms";
import { getUserToken } from "../../utils/userToken";

const DebtDetails = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { state } = useLocation();
  useEffect(() => {
    if (!state?.code) return navigate("/financial-details");
  }, [navigate, state?.code]);
  const [openUpdateCardDialog, setOpenUpdateCardDialog] = React.useState(false);
  const [openPayerConfirmationDialog, setOpenPayerConfirmationDialog] =
    React.useState(false);
  const [lastDataFetched, setLastDataFetched] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [terms, setTerms] = React.useState([]);

  const handleClickOpen = () => {
    setOpenUpdateCardDialog(true);
  };

  const handleClose = () => {
    setOpenUpdateCardDialog(false);
  };
  const { authState } = useContext(AuthContext);

  const reqForUpdateCard = (transactionCode, is3rdPartyClicked) => {
    axios
      .post(requests.CancelDebtorRepeatPayment, {
        DebtorTransactionCode: transactionCode,
        accessToken: getUserToken(),
      })
      .then((response) => {
        if (
          response?.data?.d?.SuccessCode === 0 ||
          response?.data?.d?.SuccessCode === 3
        ) {
          if (is3rdPartyClicked) {
            return navigate("/checkout-details", {
              state: {
                ...state,
                isPaymentPlanSelected: false,
                redirectURL: `${routesForLogos.getCurrentURLConfig(
                  "redirectURL"
                )}?invoiceCode=${transactionCode}&fromDebtDetailCard=${true}&updateDebtAmount=${0.01}&`,
                invoiceCode: `${transactionCode}`,
                updateCard3rdparty: true,
              },
            });
          }
          return axios
            .post(requests.CreateOpayoTakePaymentHostedFields, {
              pRequest: {
                InvoiceCode: transactionCode,
                PaymentAmount: 0.01,

                RedirectURL: `${routesForLogos.getCurrentURLConfig(
                  "redirectURL"
                )}?invoiceCode=${transactionCode}&fromDebtDetailCard=${true}&updateDebtAmount=${0.01}&`,
                GatewayProviderMerchantId:
                  routesForLogos.getCurrentURLConfig("merchantId"),
                Currency: "GBP",
                Code: authState?.user?.referenceId,
                Mode: "Debtor",
              },
              accessToken: getUserToken(),
            })
            .then((response) => {
              if (response?.data?.d?.OpayoNextUrl) {
                window.location.href = response?.data?.d?.OpayoNextUrl;
              }
            })
            .catch((err) =>
              notify(
                err?.response?.data?.d?.EnquiryResult?.Message ||
                  err?.response?.data?.Message ||
                  err
              )
            );
        } else {
          notify(response?.data?.d?.Message);
        }
      })
      .catch((err) =>
        notify(
          err?.response?.data?.d?.Message || err?.response?.data?.Message || err
        )
      );
    handleClose();
  };

  useEffect(() => {
    if (state?.code && lastDataFetched !== state?.code) {
      setLastDataFetched(state?.code);
      fetchInvoiceTerms(state?.code)
        .then((res) => {
          return setTerms(res);
        })

        .finally(() => setLoading(false));
    }
  }, [state?.code, lastDataFetched]);

  const nextPaymentDateMemo = useMemo(() => {
    const termsDateData = terms?.find((item) => item.TermAmountBalance > 0);
    return moment(termsDateData?.TermDueDate, "DD-MM-YYYY").format(
      "Do MMMM YYYY"
    );
  }, [terms]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (loading)
    return (
      <CircularProgress
        size={30}
        sx={{ position: "absolute", top: "50%", left: "50%" }}
      />
    );

  return (
    <>
      {terms?.length ? (
        <Box
          sx={{
            display: "block",
            flexDirection: "column",
            width: { xl: "50%", lg: "50%", xs: "100%" },
            minWidth: { xl: "50%", lg: "50%", xs: "auto" },
            padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
            overflow: { xl: "unset", lg: "unset", xs: "auto" },
            mt: { xs: 0, lg: 2, xl: 2 },
            flexWrap: "wrap",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon fontSize="large" />
          </Typography>
          <Typography
            variant="h5"
            fontWeight={600}
            color={(theme) => theme.palette.primary.main}
          >
            Transaction Details
          </Typography>
          <Typography variant="h6">Reference ID : {state?.code}</Typography>
          <Typography variant="h6">
            Original Balance: £
            {Number(state?.amount)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
          <Typography variant="h6">
            Current Balance : £
            {Number(state?.invoiceBalance)
              .toFixed(2)
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </Typography>
          <Typography variant="h6">
            Original Due Date: {moment(state?.startDate).format("Do MMMM YYYY")}
          </Typography>
          <Typography variant="h6">
            Next Instalment Date: {nextPaymentDateMemo}
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding={2}
            border={3}
            borderColor={(theme) => theme.palette.primary.main}
            sx={{ mt: 3, mb: state?.requestType === "changeCard" ? 0 : 2 }}
          >
            <Box
              sx={{
                overflowX: "auto",
                // maxHeight: { xs: "370px", lg: "264px", xl: "264px" },
                // height: { xs: "370px", lg: "264px", xl: "264px" },
              }}
              // display="block"
              padding="2px"
            >
              <table className="table">
                <thead>
                  <tr style={{ background: theme.palette.primary.main }}>
                    {/* <th>No.</th> */}
                    <th>Instalment Due Date</th>
                    <th>Instalment Amount</th>
                    <th>Amount Paid</th>
                  </tr>
                </thead>
                <tbody>
                  {terms.sort((a, b) => {
                    const dateA = moment(a.TermDueDate, "DD-MM-YYYY");
                    const dateB = moment(b.TermDueDate, "DD-MM-YYYY");

                    return dateA.diff(dateB);
                  }) &&
                    terms?.map((data, index) => (
                      <tr key={index}>
                        {/* <td>{index + 1}.</td> */}
                        <td>
                          {moment(data?.TermDueDate, "DD-MM-YYYY").format(
                            "Do MMMM YYYY"
                          )}
                        </td>
                        <td>
                          £
                          {Number(data.TermAmountDue)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td>
                          £
                          {Number(data.TermAmountPaid)
                            .toFixed(2)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Box>
          </Box>
          {state?.requestType === "changeCard" ? (
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{
                mt: 3,
                width: { xs: "100%", sm: "50%" },
              }}
            >
              <Button
                className="form__button"
                variant="contained"
                sx={{
                  flex: "1",
                  mb: 2,
                }}
                onClick={handleClickOpen}
              >
                Update Card
              </Button>
              <Dialog
                open={openUpdateCardDialog}
                onCloseUpdateCardDialog={handleClose}
              >
                <DialogTitle>Update your Card Details</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    To verify and update your card details, we will need to
                    process a 1p transaction. This payment will be deducted from
                    your next instalment amount.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={() => setOpenPayerConfirmationDialog(true)}>
                    Proceed
                  </Button>
                </DialogActions>
              </Dialog>
            </Box>
          ) : (
            <></>
          )}
        </Box>
      ) : (
        <></>
      )}
      <PayerConfirmationDialog
        isOpen={openPayerConfirmationDialog}
        hanldeOnClick={() => {
          setOpenPayerConfirmationDialog(false);
          reqForUpdateCard(state?.code, false);
        }}
        handleOnClick3rdPaty={() => {
          setOpenPayerConfirmationDialog(false);
          reqForUpdateCard(state?.code, true);
        }}
        handleOnClickCanel={() => setOpenPayerConfirmationDialog(false)}
      />
    </>
  );
};

export default DebtDetails;
