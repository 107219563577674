import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

const BalanceCard = ({
  availableBalance,
  bottomText,
  loading,
  debtorReference,
  fullHeightedCard,
}) => {
  const AvailableBalance = availableBalance
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        background: theme.palette.secondary.main,
        boxShadow: "10px 10px 25px rgba(41,40,40,0.6)",
        height: fullHeightedCard ? "160px" : "100px",
        width: {
          xs: "fit-content",
          sm: 350,
          md: 350,
          lg: 400,
          xl: 500,
        },
        backgroundSize: "100% 100%",
        padding: "15px",
        color: "#fff",
        mt: 3,
      })}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Box
        display="flex"
        flexDirection=""
        justifyContent="space-between"
        width="100%"
      >
        <Box className="testClass">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="start"
            width="100%"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography>Balance Outstanding</Typography>

              {loading && <CircularProgress size={10} sx={{ ml: 1 }} />}
            </Box>
          </Box>
          <Typography mt={1} variant="h3">
            £{AvailableBalance}
          </Typography>
        </Box>
        <Box textAlign="end">
          <Typography>Customer Reference</Typography>
          <Typography variant="h6">{debtorReference}</Typography>
        </Box>
      </Box>
      <Box display="flex" width="100%">
        <Typography>{bottomText}</Typography>
      </Box>
    </Box>
  );
};

export default BalanceCard;
