import { ThemeProvider } from "@mui/material/styles";
import "./App.css";
import theme from "./theme";
import { Navigate, Route, Routes } from "react-router-dom";
import ProtectedRoutes from "./ProtectedRoutes";
import Layout from "./components/Layout/Layout";
import AuthProvider from "./stores/AuthContext/AuthContext";
import CustomerDetailsProvider from "./stores/CustomerDetailsContext/CustomerDetailsContext";
import { routes } from "./routes";
import Login from "./pages/Login/Login";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import RegistrationPage from "./pages/RegistrationPage/RegistrationPage";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import RequestForResetPassword from "./pages/RequestForResetPassword/RequestForResetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ToastContainer from "./components/ToastContainer/ToastContainer";

function App() {
  return (
    <div className="app">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CustomerDetailsProvider>
            <ToastContainer />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route
                path="/request-for-reset-password"
                element={<RequestForResetPassword />}
              />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/registration" element={<RegistrationPage />} />
              <Route path="/verification" element={<VerifyEmail />} />
              <Route element={<ProtectedRoutes component={Layout} />}>
                {routes.map((component) => (
                  <Route
                    key={component.path}
                    path={component.path}
                    element={<component.element />}
                  />
                ))}
                <Route path="*" element={<Navigate to="/home" />} />
              </Route>
              <Route path="*" element={<Navigate to="/login" />} />
            </Routes>
          </CustomerDetailsProvider>
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
