import axios from "axios";
import { createContext, useContext, useEffect, useReducer } from "react";
import fetchDTInvoiceTerms from "../../apis/fetchDTInvoiceTerms";
import { debtorDetailsActions, requests } from "../../constants";
import findTotalDebt from "../../utils/findTotalDebt";
import { AuthContext } from "../AuthContext/AuthContext";
import CustomerDetailsReducer from "./CustomerDetailsReducer";
import { getUserToken } from "../../utils/userToken";
export const CustomerDetailsContext = createContext({});

const CustomerDetailsProvider = ({ children }) => {
  const [debtorDetailsState, debtorDetailsDispatch] = useReducer(
    CustomerDetailsReducer,
    {}
  );
  const { authState } = useContext(AuthContext);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      if (authState?.user?.referenceId) {
        try {
          const debtorDetails = await axios.post(requests.GetDebtor, {
            accessToken: getUserToken(),
            DebtorReference: authState?.user?.referenceId,
          });
          if (debtorDetails?.data?.d?.EnquiryResult?.SuccessCode === 0) {
            const transactionsDetails = await axios.post(
              requests.GetDebtorsTransactionListJSON,
              {
                accessToken: getUserToken(),
                Status: "",
                SearchType: "DebtorId",
                SearchText: debtorDetails?.data?.d?.Debtor?.DebtorCode,
                startDate: "",
                endDate: "",
                recordStartNo: 1,
                recordEndNo: 10000,
              }
            );

            if (Object.keys(transactionsDetails).length) {
              debtorDetailsDispatch({
                type: debtorDetailsActions.ADDDETAILS,
                payload: {
                  ...debtorDetails?.data?.d?.Debtor,
                  Transactions: transactionsDetails.data.d.Records,
                  TotalDebt: findTotalDebt(transactionsDetails.data.d.Records),
                },
              });
              fetchDTInvoiceTerms(transactionsDetails?.data?.d?.Records)
                .then((response) => {
                  debtorDetailsDispatch({
                    type: debtorDetailsActions.ADDTRANSACTIONTERMS,
                    payload: {
                      TransactionsTerms: response,
                    },
                  });
                  return;
                })
                .catch((err) => {
                  return console.log(
                    err?.response?.data?.Message ||
                      err?.response?.data?.message ||
                      err
                  );
                });

              return;
            }
          }
        } catch (err) {
          return console.log(
            err?.response?.data?.Message || err?.response?.data?.message || err
          );
        }
      }
    };
    if (!["/home", "/financial-details"].includes(window.location.pathname))
      fetchCustomerDetails();
  }, [authState?.user?.referenceId, debtorDetailsDispatch]);

  return (
    <CustomerDetailsContext.Provider
      value={{ debtorDetailsState, debtorDetailsDispatch }}
    >
      {children}
    </CustomerDetailsContext.Provider>
  );
};

export default CustomerDetailsProvider;
