import React, { useState } from "react";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";

import * as yup from "yup";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import AuthContainer from "../../components/AuthContainer/AuthContainer";
import { cssClasses, requests } from "../../constants";
import instance from "../../apis/axios";
import { routesForLogos } from "../../utils/routesForLogos";

const RequestForResetPassword = () => {
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const [msg, setmsg] = useState("");
  const [error, seterror] = useState("");
  const [loading, setloading] = useState(false);

  const emailValidationSchema = yup.object({
    email: yup
      .string("Please enter email")
      .email("Please enter valid email.")
      .required("email is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidationSchema,
    onSubmit: (values) => {
      setloading(true);
      instance
        .post(requests.requestForResetPassword, {
          email: values.email,
          domain: routesForLogos.getCurrentURLConfig("domain"),
        })
        .then(({ data }) => {
          if (data.message && data.status) {
            setmsg(
              "If the email address provided is found, we will send an email with a link to reset your password."
            );
            setloading(false);
          }
        })
        .catch((err) => {
          setloading(false);
          seterror(err?.response?.data?.message || err);
        });
    },
  });

  return (
    <AuthContainer>
      {msg ? (
        <div className={auth.formTitleLeft} style={{ padding: "10px" }}>
          <Typography
            variant="h6"
            color={(theme) => theme.palette.secondary.main}
          >
            {msg}
          </Typography>
          <Typography
            variant="h6"
            sx={{ mt: 3, cursor: "pointer" }}
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate("/login")}
          >
            Go to Login
          </Typography>
        </div>
      ) : (
        <>
          <div className={auth.formTitleLeft}>
            <Typography
              color={(theme) => theme.palette.primary.main}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon fontSize="large" />
            </Typography>
            <div className={auth.formHeading}>
              <Typography
                variant="h4"
                color={(theme) => theme.palette.primary.main}
              >
                RESET PASSWORD
              </Typography>
            </div>
          </div>
          <form className={auth.form} onSubmit={formik.handleSubmit}>
            <TextField
              placeholder="Email"
              variant="outlined"
              fullWidth
              sx={{ mt: 3 }}
              InputProps={{
                autoComplete: "off",
                className: auth.formInput,
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonOutlinedIcon />
                  </InputAdornment>
                ),
              }}
              id="email"
              name="email"
              type="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={(formik.touched.email && formik.errors.email) || " "}
            />
            <Button
              fullWidth
              className={auth.formButton}
              variant="contained"
              sx={{ mt: 3 }}
              type="submit"
              disabled={loading}
            >
              Proceed
            </Button>
            {error && (
              <Typography color="red" sx={{ mt: 1, maxWidth: "300px" }}>
                {error}
              </Typography>
            )}
          </form>
        </>
      )}
    </AuthContainer>
  );
};

export default RequestForResetPassword;
