// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header__logo {
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
  display: block;
  max-width: 125px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.header__logo:hover {
  opacity: 0.8;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,cAAc;EACd,gBAAgB;EAChB,eAAe;EACf,gCAAgC;AAClC;;AAEA;EACE,YAAY;AACd","sourcesContent":[".header__logo {\r\n  height: 50px;\r\n  object-fit: contain;\r\n  margin-right: 10px;\r\n  display: block;\r\n  max-width: 125px;\r\n  cursor: pointer;\r\n  transition: all 0.3s ease-in-out;\r\n}\r\n\r\n.header__logo:hover {\r\n  opacity: 0.8;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
