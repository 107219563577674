import Dashboard from "./pages/Dashboard/Dashboard";
import DebtDetails from "./pages/DebtDetails/DebtDetails";
import FailPayment from "./pages/FailPayment/FailPayment";
import FinancialDetails from "./pages/FinancialDetails/FinancialDetails";
import MakePayment from "./pages/MakePayment/MakePayment";
import PaymentPlanChoice from "./pages/PaymentPlanChoice/PaymentPlanChoice";
import CheckOutDetails from "./pages/CheckOutDetails/CheckOutDetails";
import PaymentPlanConfirmation from "./pages/PaymentPlanConfirmation/PaymentPlanConfirmation";
import ProcessingPayment from "./pages/ProcessingPayment/ProcessingPayment";
import SuccessPayment from "./pages/SuccessPayment/SuccessPayment";
import NeedAdditionalSupport from "./pages/NeedAdditionalSupport/NeedAdditionalSupport";

export const routes = [
  {
    path: "/home",
    element: Dashboard,
  },
  {
    path: "/financial-details",
    element: FinancialDetails,
  },
  {
    path: "/make-payment",
    element: MakePayment,
  },
  {
    path: "/payment-plan",
    element: PaymentPlanChoice,
  },
  {
    path: "/checkout-details",
    element: CheckOutDetails,
  },
  {
    path: "/payment-success",
    element: SuccessPayment,
  },
  {
    path: "/payment-fail",
    element: FailPayment,
  },
  {
    path: "/processing-payment",
    element: ProcessingPayment,
  },
  {
    path: "/payment-plan-confirmation",
    element: PaymentPlanConfirmation,
  },
  {
    path: "/debt-details",
    element: DebtDetails,
  },
  {
    path: "/need-additional-support",
    element: NeedAdditionalSupport,
  },
];
