import { Button, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { cssClasses, requests } from "../../constants";
import { useFormik } from "formik";
import * as yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import moment from "moment/moment";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";
import { routesForLogos } from "../../utils/routesForLogos";
import { getUserToken } from "../../utils/userToken";

const CheckOutDetails = () => {
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const [loading, setLoading] = useState(false);
  const { authState } = useContext(AuthContext);

  const location = useLocation();

  const textInputs = [
    {
      name: "PayeeName",
      validation: yup.string().required("First Name is required"),
      type: "text",
      placeholder: "First Name",
    },
    {
      name: "PayeeSurname",
      validation: yup.string().required("Surname is required"),
      type: "text",
      placeholder: "Surname",
    },
    {
      name: "HouseNumber",
      validation: yup.string().required("House Number is required"),
      type: "text",
      placeholder: "House Number",
    },
    {
      name: "HouseName",
      // validation: yup.string().required("House Name is required"),
      type: "text",
      placeholder: "House Name",
    },
    {
      name: "Address1",
      validation: yup.string().required("Address1 is required"),
      type: "text",
      placeholder: "Address Line 1",
    },
    {
      name: "Address2",
      validation: yup.string().required("Address2 is required"),
      type: "text",
      placeholder: "Address Line 2",
    },
    {
      name: "Address3",
      validation: yup.string().required("City is required"),
      type: "text",
      placeholder: "City",
    },
    {
      name: "Address4",
      validation: yup.string().required("Address4 is required"),
      type: "text",
      placeholder: "County",
    },
    {
      name: "PostCode",
      validation: yup.string().required("PostCode is required"),
      type: "text",
      placeholder: "Postcode",
    },
    // {
    //   name: "AccountName",
    //   validation: yup.string().required("AccountName is required"),
    //   type: "text",
    //   placeholder: "Account Name",
    // },
    // {
    //   name: "AccountNo",
    //   validation: yup.string().required("AccountNo is required"),
    //   type: "text",
    //   placeholder: "Account No",
    // },
    // {
    //   name: "SortCode",
    //   validation: yup.string().required("SortCode is required"),
    //   type: "text",
    //   placeholder: "Sort Code",
    // },
    {
      name: "Email",
      validation: yup
        .string()
        .email("Please enter valid email")
        .required("Email is required"),
      type: "email",
      placeholder: "Email",
    },
  ];

  const initialValuesObject = textInputs.reduce(
    (acc, input) => ({ ...acc, [input.name]: input?.value || "" }),
    {}
  );
  const validationSchemaObject = textInputs.reduce(
    (acc, input) => ({ ...acc, [input.name]: input.validation }),
    {}
  );

  const updateDebtorTransaction = (payload) => {
    return axios
      .post(requests.UpdateDebtorTransaction, {
        pRequest: {
          ...payload,
        },
        accessToken: getUserToken(),
      })
      .then((responseData) => {
        if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
          return responseData?.data?.d?.ThirdPartyDetails?.length
            ? responseData?.data?.d?.ThirdPartyDetails?.[
                responseData?.data?.d?.ThirdPartyDetails?.length - 1
              ]
            : null;
        }
      })
      .catch((err) => console.log(err));
  };

  // extraPayload will accept the extra values which are required.
  // for example, if there's a plan selected than it will accept
  // Recurring & FirstPaymentDate as extra parameters for CreateOpayoTakePaymentHostedFields.
  const invokeOpayoHostedFields = () => {
    return axios
      .post(requests.CreateOpayoTakePaymentHostedFields, {
        pRequest: {
          InvoiceCode: location?.state?.invoiceCode,
          PaymentAmount: location?.state?.updateCard3rdparty
            ? 0.01
            : Number(location?.state?.PaymentAmount),

          RedirectURL: location?.state?.redirectURL,

          GatewayProviderMerchantId:
            routesForLogos.getCurrentURLConfig("merchantId"),
          Currency: "GBP",
          Code: authState?.user?.referenceId,
          Mode: "Debtor",
          // ...extraPayload,
        },
        accessToken: getUserToken(),
      })
      .then((response) => {
        if (response?.data?.d?.OpayoNextUrl) {
          window.location.href = response?.data?.d?.OpayoNextUrl;
          // window.open(response?.data?.d?.OpayoNextUrl, "_blank");
          // navigate("/processing-payment", {
          //   state: {
          //     paymentPlan: location?.state?.paymentPlan,
          //     transactionCode: response?.data?.d?.TransactionLogReference,
          //     invoiceCode: location?.state?.transactionCode,
          //   },
          // });
        } else {
          notify(
            response?.data?.d?.EnquiryResult?.Message ||
              "Unable to process payment, please try again."
          );
        }
      })
      .catch((err) =>
        notify(
          err?.response?.data?.d?.EnquiryResult?.Message ||
            "Unable to process payment, please try again."
        )
      )
      .finally(() => setLoading(false));
  };

  // const handlePaymentPlanChoice = () => {
  //   return axios
  //     .post(requests.CreateDTTermsCustom, {
  //       pRequest: {
  //         DTCode: location?.state?.transactionCode,
  //         TermList: location?.state?.paymentPlan.map((d) => ({
  //           Term: null,
  //           TermPer: null,
  //           Amount: d?.amount.toString(),
  //           DueDate: moment(d?.date).format("DD-MMM-yyyy"),
  //         })),
  //       },
  //       UserInfo: {
  //         Username: process.env.REACT_APP_USERNAME,
  //         Password: process.env.REACT_APP_PASSWORD,
  //       },
  //     })
  //     .then((response) => {
  //       if (response?.data?.d?.EnquiryResult?.SuccessCode === 0) {
  //         const extraPayload = {
  //           PaymentAmount: Number(location?.state?.paymentPlan?.[0]?.amount),
  //           Recurring: true,
  //           FirstPaymentDate:
  //             moment(location?.state?.formValues?.planStartDate).format(
  //               "DD-MM-YYYY"
  //             ) || moment().format("DD-MM-YYYY"),
  //         };
  //         return extraPayload;
  //       } else {
  //         notify(
  //           response?.data?.d?.EnquiryResult?.Message ||
  //             "Unable to process recurring payment, please try again."
  //         );
  //       }
  //     })
  //     .catch((err) =>
  //       notify(
  //         err?.response?.data?.d?.EnquiryResult?.Message ||
  //           "Unable to process recurring payment, please try again."
  //       )
  //     );
  // };

  const handleSubmit = async (values) => {
    setLoading(true);
    let extraPayloadForOpayo = {
      // ThirdPartyID: 0,
    };

    // if (location?.state?.isPaymentPlanSelected) {
    //   const extraPayloadFromPlanChoice = await handlePaymentPlanChoice();
    //   extraPayloadForOpayo = {
    //     ...extraPayloadForOpayo,
    //     ...extraPayloadFromPlanChoice,
    //   };
    // }

    // if (location?.state?.isPaymentPlanSelected) {
    //   extraPayloadForOpayo = {
    //     ...extraPayloadForOpayo,
    //   };
    // }

    const payloadForUpdateDebotTransaction = {
      DebtorTransactionCode: location?.state?.transactionCode,
      ThirdPartyDetails: [
        {
          DebtorTransactionCode: location?.state?.transactionCode,
          ...values,
        },
      ],
    };
    const thirdPartyDetails = await updateDebtorTransaction(
      payloadForUpdateDebotTransaction
    );

    if (thirdPartyDetails?.Id) {
      extraPayloadForOpayo["ThirdPartyID"] = thirdPartyDetails?.Id;
    } else {
      extraPayloadForOpayo["ThirdPartyID"] = 0;
    }

    await invokeOpayoHostedFields();
  };

  const formik = useFormik({
    initialValues: initialValuesObject,
    validationSchema: yup.object(validationSchemaObject),
    onSubmit: handleSubmit,
  });

  return (
    <Box
      sx={{
        display: "block",
        flexDirection: "column",
        width: { xl: "30%", lg: "30%", xs: "100%" },
        minWidth: { xl: "30%", lg: "30%", xs: "auto" },
        padding: { xl: "0 35%", lg: "0 35%", xs: "15px" },
        overflow: { xl: "unset", lg: "unset", xs: "auto" },
        mt: { xs: 0, lg: 2, xl: 2 },
        flexWrap: "wrap",
      }}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Typography
        color={(theme) => theme.palette.primary.main}
        onClick={() => navigate(-1)}
      >
        <ArrowBackIcon fontSize="large" />
      </Typography>
      <Typography
        variant="h5"
        fontWeight={600}
        color={(theme) => theme.palette.primary.main}
        sx={{ textTransform: "uppercase", mb: 1 }}
      >
        Alternative Cardholder Information
      </Typography>
      <Typography
        variant="caption"
        color={(theme) => theme.palette.primary.secondary}
      >
        Please fill all the required fields.
      </Typography>
      <form
        className={auth.form}
        style={{ marginTop: "15px", paddingBottom: "15px" }}
        onSubmit={formik.handleSubmit}
      >
        {textInputs.map((textInput) => (
          <TextField
            key={textInput.name}
            placeholder={textInput.placeholder}
            variant="outlined"
            fullWidth
            sx={{ mt: 1 }}
            InputProps={{
              autoComplete: "off",
              className: auth.formInput,
            }}
            id={textInput.name}
            name={textInput.name}
            type={textInput.type}
            value={formik.values[textInput.name]}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched?.[textInput.name] &&
              Boolean(formik.errors?.[textInput.name])
            }
            helperText={
              (formik.touched?.[textInput.name] &&
                formik.errors?.[textInput.name]) ||
              " "
            }
          />
        ))}

        <Button
          fullWidth
          className={auth.formButton}
          variant="contained"
          sx={{ mt: 2, width: "40%" }}
          type="submit"
          disabled={loading}
        >
          Proceed
        </Button>
      </form>
    </Box>
  );
};

export default CheckOutDetails;
