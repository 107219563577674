import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Button,
  CircularProgress,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthContainer from "../../components/AuthContainer/AuthContainer";
import { authActions, cssClasses, requests } from "../../constants";
import instance from "../../apis/axios";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import * as yup from "yup";
import { useFormik } from "formik";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const ResetPassword = () => {
  const [queryParams] = useSearchParams();
  const token = queryParams.get("id");
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const { authDispatch } = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const [email, setemail] = useState("");
  const [error, seterror] = useState("");

  const verifyEmail = useCallback(
    async (token) => {
      setloading(true);
      try {
        const response = await instance.post(requests.verifyResetPasswordLink, {
          token,
        });
        if (response.data?.status && response.data?.email) {
          setemail(response.data?.email);
          setloading(false);
        }
      } catch (err) {
        navigate("/login");
        setloading(false);
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (token) verifyEmail(token);
  }, [token, verifyEmail]);

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token, navigate]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userAuth"));
    if (userData) {
      navigate("/login");
    }
  }, [navigate]);

  const resetPassValidationSchema = yup.object({
    password: yup
      .string("Please enter password.")
      .min(12, "password should be more than 12 characters")
      .required("Password is required"),
    passwordConfirmation: yup
      .string("Please enter confirm password.")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  });

  const resetPassFormik = useFormik({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: resetPassValidationSchema,
    onSubmit: (values) => {
      setloading(true);
      if (values.password === values.passwordConfirmation)
        instance
          .post(requests.resetPassword, {
            email,
            password: values.password,
          })
          .then(({ data }) => {
            if (data.accessToken) {
              authDispatch({
                type: authActions.LOGIN,
                payload: data,
              });
              setloading(false);
              return navigate("/home");
            }
          })
          .catch((err) => {
            setloading(false);
            seterror(err?.response?.data?.message || err);
          });
    },
  });

  return (
    <AuthContainer>
      {loading ? (
        <div className={auth.form}>
          <CircularProgress />
        </div>
      ) : (
        email && (
          <>
            {" "}
            <div className={auth.formTitleLeft}>
              <Typography
                color={(theme) => theme.palette.primary.main}
                onClick={() => navigate("/login")}
              >
                <ArrowBackIcon fontSize="large" />
              </Typography>
              <div className={auth.formHeading}>
                <Typography
                  variant="h4"
                  color={(theme) => theme.palette.primary.main}
                >
                  RESET PASSWORD
                </Typography>
              </div>
            </div>
            <form className={auth.form} onSubmit={resetPassFormik.handleSubmit}>
              <TextField
                placeholder="Create Password"
                fullWidth
                sx={{ mt: 3 }}
                InputProps={{
                  autoComplete: "off",
                  className: auth.formInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="password"
                name="password"
                type="password"
                value={resetPassFormik.values.password}
                onChange={resetPassFormik.handleChange}
                onBlur={resetPassFormik.handleBlur}
                error={
                  resetPassFormik.touched.password &&
                  Boolean(resetPassFormik.errors.password)
                }
                helperText={
                  (resetPassFormik.touched.password &&
                    resetPassFormik.errors.password) ||
                  " "
                }
              />
              <TextField
                placeholder="Confirm New Password"
                fullWidth
                sx={{ mt: 3 }}
                InputProps={{
                  autoComplete: "off",
                  className: auth.formInput,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                id="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                value={resetPassFormik.values.passwordConfirmation}
                onChange={resetPassFormik.handleChange}
                onBlur={resetPassFormik.handleBlur}
                error={
                  resetPassFormik.touched.passwordConfirmation &&
                  Boolean(resetPassFormik.errors.passwordConfirmation)
                }
                helperText={
                  (resetPassFormik.touched.passwordConfirmation &&
                    resetPassFormik.errors.passwordConfirmation) ||
                  " "
                }
              />
              <Button
                fullWidth
                className={auth.formButton}
                variant="contained"
                sx={{ mt: 3 }}
                type="submit"
                disabled={loading}
              >
                Proceed
              </Button>
              {error && (
                <Typography color="red" sx={{ mt: 1, maxWidth: "300px" }}>
                  {error}
                </Typography>
              )}
            </form>
          </>
        )
      )}
    </AuthContainer>
  );
};

export default ResetPassword;
