import React, { useCallback, useContext, useState } from "react";
import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { useFormik } from "formik";
import { useNavigate, Link, useLocation } from "react-router-dom";

import * as yup from "yup";

import { useEffect } from "react";
import { authActions, cssClasses } from "../../constants";
import {
  getNewAccessToken,
  login,
  loginThroughToken,
} from "../../apis/authFunctions";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import AuthContainer from "../../components/AuthContainer/AuthContainer";
import Logo from "../../components/Logo/Logo";
import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const location = useLocation();
  const from = location.state?.from || "/";
  const { authDispatch } = useContext(AuthContext);
  const [error, seterror] = useState("");
  const [loadingPage, setLoadingPage] = useState({});

  const validationSchema = yup.object({
    email: yup
      .string("Please enter email")
      .email("Please enter valid email.")
      .required("email is required"),
    password: yup
      .string("Please enter password.")
      .min(12, "password should be more than 12 characters")
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      login(values.email, values.password)
        .then((response) => {
          if (response.data?.user?.referenceId) {
            authDispatch({ type: authActions.LOGIN, payload: response.data });
            return navigate("/home");
          }
        })
        .catch((err) => {
          let errMsg = err.toString();
          errMsg = errMsg.substring(errMsg.indexOf(" "));
          return seterror(errMsg || err);
        });
    },
  });

  const getToken = useCallback(() => {
    const userData = JSON.parse(sessionStorage.getItem("userAuth"));
    setLoadingPage(userData);
    getNewAccessToken(userData?.refreshToken)
      .then((response) => {
        if (response?.data?.accessToken) {
          authDispatch({
            type: authActions.GOTTOKEN,
            payload: response?.data,
          });
          return navigate(
            from?.pathname
              ? `${from.pathname}${
                  from?.search ? from?.search?.replaceAll("&?", "&") : ""
                }`
              : `${
                  location.pathname !== "/login"
                    ? location.pathname !== "/signup"
                      ? location.pathname
                      : "/home"
                    : "/home"
                }`
          );
        }
      })
      .catch((err) => {
        authDispatch({ type: authActions.LOGOUT });
        let errMsg = err.toString();
        errMsg = errMsg.substring(errMsg.indexOf(" "));
        return seterror(errMsg || err);
      });
  }, [authDispatch, from?.pathname, location.pathname, navigate]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userAuth"));
    setLoadingPage(userData);
    if (userData?.user?.referenceId) {
      loginThroughToken(userData?.accessToken)
        .then((response) => {
          if (response.data.success) {
            authDispatch({
              type: authActions.LOGINTHROUGHTOKEN,
              payload: userData,
            });

            return navigate(
              from?.pathname
                ? `${from.pathname}${
                    from?.search ? from?.search?.replaceAll("&?", "&") : ""
                  }`
                : `${
                    location.pathname !== "/login"
                      ? location.pathname !== "/signup"
                        ? location.pathname
                        : "/home"
                      : "/home"
                  }`
            );
          }
        })
        .catch((err) => {
          if (err?.message === "Token is expired.") {
            getToken();
            return;
          }
          authDispatch({ type: authActions.LOGOUT });
          let errMsg = err.toString();
          errMsg = errMsg.substring(errMsg.indexOf(" "));
          return seterror(errMsg || err);
        });
    }
  }, [
    navigate,
    authDispatch,
    from.pathname,
    location.pathname,
    getToken,
    formik.values,
  ]);

  return loadingPage ? (
    <div className="loginLoadingPage">
      <div className="loader"></div>
    </div>
  ) : (
    <AuthContainer isloginForm>
      <div className={auth.formTitle}>
        <Logo alt="logo" className={auth.formLogo} />
      </div>
      <form className={auth.form} onSubmit={formik.handleSubmit}>
        <TextField
          placeholder="Email"
          variant="outlined"
          fullWidth
          sx={{ mt: 3, maxWidth: "300px" }}
          InputProps={{
            autoComplete: "off",
            className: auth.formInput,
            startAdornment: (
              <InputAdornment position="start">
                <PersonOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="email"
          name="email"
          type="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={(formik.touched.email && formik.errors.email) || " "}
        />
        <TextField
          placeholder="Password"
          fullWidth
          sx={{ mt: 3, maxWidth: "300px" }}
          InputProps={{
            autoComplete: "off",
            className: auth.formInput,
            startAdornment: (
              <InputAdornment position="start">
                <LockOutlinedIcon />
              </InputAdornment>
            ),
          }}
          id="password"
          name="password"
          type="password"
          value={formik.values.password}
          onChange={(e) => {
            seterror("");
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          error={
            formik.touched.password && formik.errors.password
              ? true
              : error
              ? true
              : false
          }
          helperText={
            (formik.touched.password && formik.errors.password) ||
            (error && error) ||
            " "
          }
        />
        <Button
          fullWidth
          className={auth.formButton}
          variant="contained"
          sx={{ mt: 3, maxWidth: "300px" }}
          type="submit"
        >
          Login
        </Button>
        <div className={auth.formResetPassSpan}>
          <Link
            style={{ textDecoration: "none" }}
            to="/request-for-reset-password"
            state={{ from: "/login" }}
          >
            <Typography color={(theme) => theme.palette.secondary.main}>
              Reset Password?
            </Typography>
          </Link>
        </div>
      </form>
    </AuthContainer>
  );
};

export default Login;
