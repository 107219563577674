import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import { cssClasses, requests } from "../../constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";

import "../Dashboard/Dashboard.css";
import axios from "axios";
import { routesForLogos } from "../../utils/routesForLogos";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";
import PayerConfirmationDialog from "../../components/PayerConfirmationDialog/PayerConfirmationDialog";
import { getUserToken } from "../../utils/userToken";

const MakePayment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const [openPayerConfirmationDialog, setOpenPayerConfirmationDialog] =
    useState(false);

  const submitHandler = (e) => {
    // e.preventDefault();
    axios
      .post(requests.CreateOpayoTakePaymentHostedFields, {
        pRequest: {
          InvoiceCode: location?.state?.transactionCode,
          PaymentAmount: Number(location?.state?.amount),
          RedirectURL: `${routesForLogos.getCurrentURLConfig(
            "redirectURL"
          )}?invoiceCode=${location?.state?.transactionCode}&amount=${Number(
            location?.state?.amount
          )}&`,
          GatewayProviderMerchantId:
            routesForLogos.getCurrentURLConfig("merchantId"),
          Currency: "GBP",
          Code: authState?.user?.referenceId,
          Mode: "Debtor",
        },
        accessToken: getUserToken(),
      })
      .then((response) => {
        if (response?.data?.d?.OpayoNextUrl) {
          window.location.href = response?.data?.d?.OpayoNextUrl;
        } else {
          notify(
            response?.data?.d?.EnquiryResult?.Message ||
              "Unable to process payment, please try again."
          );
        }
      })
      .catch((err) =>
        notify(
          err?.response?.data?.d?.EnquiryResult?.Message ||
            "Unable to process payment, please try again."
        )
      );
  };

  useEffect(() => {
    if (!location?.state) return navigate("/financial-details");
  }, [location, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: { xl: "50%", lg: "50%", xs: "100%" },
          minWidth: { xl: "50%", lg: "50%", xs: "auto" },
          padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
          mt: { xs: 0, lg: 2, xl: 2 },
          flexWrap: "wrap",
        }}
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Typography
          color={(theme) => theme.palette.primary.main}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon fontSize="large" />
        </Typography>
        <Typography
          variant="h5"
          fontWeight={600}
          color={(theme) => theme.palette.primary.main}
        >
          Make a Payment
        </Typography>
        <form style={{ width: "100%" }} onSubmit={submitHandler}>
          <TextField
            placeholder="Amount"
            variant="outlined"
            fullWidth
            sx={{
              mt: 3,
              pointerEvents: "none",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            InputProps={{
              autoComplete: "off",
              className: cssClasses.auth.formInput,
              sx: (theme) => ({
                border: `2px solid ${theme?.palette?.primary?.main}`,
                color: theme?.palette?.primary?.main,
                fontWeight: "bold",
                fontSize: "1.3rem",
              }),
              startAdornment: (
                <InputAdornment position="start">
                  <CurrencyPoundIcon />
                </InputAdornment>
              ),
            }}
            name="amount"
            value={Number(location?.state?.amount).toFixed(2)}
          />
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            sx={{
              mt: 3,
              flexWrap: { xs: "wrap", lg: "nowrap", xl: "nowrap" },
            }}
          >
            <Box
              display="flex"
              width="100%"
              sx={{
                mb: { xs: 2, lg: 0, xl: 0 },
              }}
            >
              <Button
                // type="submit"
                onClick={() => setOpenPayerConfirmationDialog(true)}
                className="form__button"
                variant="contained"
                sx={{
                  flex: "1 1 auto",
                  display: "flex",
                  fontSize: { sm: 15, md: 15, lg: 12, xl: 15 },
                }}
              >
                Payment in Full
              </Button>
            </Box>
            <Box
              display="flex"
              width="100%"
              sx={{
                ml: { xs: 0, lg: 2, xl: 2 },
                mb: { xs: 2, lg: 0, xl: 0 },
              }}
            >
              <Button
                className="form__button"
                variant="contained"
                sx={{
                  flex: "1 1 auto",
                  fontSize: { sm: 15, md: 15, lg: 12, xl: 15 },
                }}
                onClick={() => {
                  if (location?.state?.amount) {
                    navigate("/payment-plan", {
                      state: { ...location?.state },
                    });
                  }
                }}
              >
                Choose a Payment Plan
              </Button>
            </Box>
            <Box
              display="flex"
              sx={{
                mb: { xs: 2, lg: 0, xl: 0 },
                width: { xs: "100%", lg: "70%", xl: "70%" },
              }}
            ></Box>
          </Box>
        </form>
      </Box>
      <PayerConfirmationDialog
        isOpen={openPayerConfirmationDialog}
        hanldeOnClick={() => {
          setOpenPayerConfirmationDialog(false);
          submitHandler();
        }}
        handleOnClick3rdPaty={() => {
          setOpenPayerConfirmationDialog(false);
          return navigate("/checkout-details", {
            state: {
              ...location.state,
              isPaymentPlanSelected: false,
              redirectURL: `${routesForLogos.getCurrentURLConfig(
                "redirectURL"
              )}?invoiceCode=${location?.state?.transactionCode}&`,
              invoiceCode: `${location?.state?.transactionCode}`,
              PaymentAmount: Number(location?.state?.amount),
            },
          });
        }}
        handleOnClickCanel={() => {
          setOpenPayerConfirmationDialog(false);
        }}
      />
    </>
  );
};

export default MakePayment;
