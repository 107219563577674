import moment from "moment";
import axios from "axios";
import { requests } from "../constants";
import { getUserToken } from "./userToken";
import { fetchInvoiceTerms } from "../apis/fetchDTInvoiceTerms";

export default function findTotalDebt(transactions) {
  // if (transactions.length) {
  //   var totalAmount = 0;
  //   transactions.forEach(
  //     (transaction) => (totalAmount += Number(transaction?.TotalAmount))
  //   );
  //   return totalAmount;
  // }

  // New approach to count outstanding amount only to display total outstanding amount.
  try {
    const totalOutstanding = transactions.reduce(
      (acc, transaction) => acc + +transaction.InvoiceBalance,
      0
    );
    return totalOutstanding;
  } catch (error) {
    return 0;
  }
}

export function findNextTransaction(transactions) {
  if (transactions.length) {
    const futureActive = transactions
      .filter(
        (item) =>
          Number(item?.InvoiceBalance) &&
          Number(item?.InvoiceBalance) < Number(item?.TotalAmount)
      )
      .map(async (i) => {
        const invoiceTerms = await axios.post(requests.GetDTInvoiceTerms, {
          accessToken: getUserToken(),
          DebtorTransactionCode: i?.Code,
        });

        return {
          // ...i,
          Term: invoiceTerms.data.d.Terms.find(
            (item) =>
              new Date(moment(item.TermDueDate).format("DD-MM-YYYY")) >
              new Date()
          ),
          InvoiceBalance: i.InvoiceBalance,
          InvoiceDate: moment(i?.InvoiceDate).format("DD-MM-YYYY"),
        };
      });

    return Promise.all(futureActive).then((res) => {
      return res
        ?.filter((item) => !!item.Term)
        ?.sort(
          (a, b) =>
            new Date(moment(a.Term.TermDueDate).format("DD-MM-YYYY")) -
            new Date(moment(b.Term.TermDueDate).format("DD-MM-YYYY"))
        )?.[0]?.Term;
    });
  }
}

// export async function findNextTransactionDate(transactions) {
//   console.log("transactions", transactions);

//   const transactionCode = transactions?.[0]?.Code;
//   if (!transactionCode) {
//     return null;
//   }

//   try {
//     const terms = await fetchInvoiceTerms(transactions[0]?.Code).then((res) => {
//       return res;
//     });

//     const termsDateData = terms?.find((item) => item.TermAmountBalance > 0);

//     if (!termsDateData) {
//       return null;
//     }
//     return moment(termsDateData?.TermDueDate, "DD-MM-YYYY").format(
//       "Do MMMM YYYY"
//     );
//   } catch (error) {
//     console.error("Error fetching data:", error);
//     return null;
//   }
// }

export async function findNextTransactionDate(transactions) {
  const transactionCodes = transactions.map((data) => data?.Code);

  if (!transactionCodes || transactionCodes.length === 0) {
    return null;
  }

  try {
    const termsPromises = transactionCodes.map((code) =>
      fetchInvoiceTerms(code)
    );

    const termsResponses = await Promise.all(termsPromises);

    const allTerms = termsResponses.flatMap((terms) => terms);

    const validTerms = allTerms.filter((item) => item.TermAmountBalance > 0);
    console.log("validTerms", validTerms);
    if (validTerms.length === 0) {
      return null;
    }

    const earliestTerm = validTerms.reduce((earliest, current) => {
      const currentDueDate = moment(current.TermDueDate, "DD-MM-YYYY");
      const earliestDueDate = moment(earliest.TermDueDate, "DD-MM-YYYY");
      return currentDueDate.isBefore(earliestDueDate) ? current : earliest;
    });

    return moment(earliestTerm.TermDueDate, "DD-MM-YYYY").format(
      "Do MMMM YYYY"
    );
  } catch (error) {
    console.error("Error fetching data:", error);
    return null;
  }
}
