const crmURL = process.env.REACT_APP_CRM_URL;

export const cssClasses = {
  auth: {
    formTitle: "sub__conatiner--form--title",
    formTitleLeft: "sub__conatiner--form--left--title",
    formHeading: "sub__conatiner--form--heading",
    formLogo: "sub__conatiner--form--logo",
    form: "sub__conatiner--form--elements",
    formInput: "sub__conatiner--form--input",
    formButton: "sub__conatiner--form--button",
    formResetPassSpan: "sub__conatiner--form--reset",
    formSignupSpan: "sub__conatiner--form--signup",
  },
};

export const authActions = {
  LOGIN: "LOGIN",
  LOGINTHROUGHTOKEN: "LOGIN_THROUGH_TOKEN",
  GOTTOKEN: "GOT_NEW_TOKEN",
  LOGOUT: "LOGOUT",
  PASSWORDCHANGED: "PASSWORD_CHANGED",
};

export const debtorDetailsActions = {
  ADDDETAILS: "ADD_CUSTOMER_DETAILS",
  ADDTRANSACTIONTERMS: "ADD_TRANSACTION_TERMS",
  REMOVEDETAILS: "REMOVE_CUSTOMER_DETAILS",
};

export const requests = {
  login: `/auth/login`,
  loginThroughToken: `/auth/login-through-token`,
  getToken: `/auth/get-access-token`,
  requestForResetPassword: "/auth/request-for-reset-password",
  verifyResetPasswordLink: "/auth/verify-reset-password-link",
  resetPassword: "/auth/reset-password",
  isCustomerEligibleForRegistration: `/auth/is-customer-eligible-for-registration`,
  register: "/auth/register",
  verifyEmail: "/auth/verify-email",
  braintreeCheckout: "/auth/braintree-checkout",
  GetDebtor: `${crmURL}/GetDebtor`,
  CreateOpayoTakePaymentHostedFields: `${crmURL}/CreateOpayoTakePaymentHostedFields`,
  GetTakePaymentTransaction: `${crmURL}/GetTakePaymentTransaction`,
  GetDebtorsTransactionListJSON: `${crmURL}/GetDebtorsTransactionListJSON`,
  GetDTInvoiceTerms: `${crmURL}/GetDTInvoiceTerms`,
  GetDTGroupInvoiceTerms: `${crmURL}/GetDTGroupInvoiceTerms`,
  UpdateDTGroupInvoiceTerms: `${crmURL}/UpdateDTGroupInvoiceTerms`,
  CancelDebtorRepeatPayment: `${crmURL}/CancelDebtorRepeatPayment`,
  UpdateDebtorTransaction: `${crmURL}/UpdateDebtorTransaction`,
  CreateDTTermsCustom: `${crmURL}/CreateDTTermsCustom`,
  CreatePaymentReceipt: `${crmURL}/CreatePaymentReceipt`,
  UpdateDebtor: `${crmURL}/UpdateDebtor`,
};

export const alertConfig = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const debtStatus = {
  NOTPAID: "Not Paid",
  PENDING: "Pending",
  PAID: "Paid",
};
