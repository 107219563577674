import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import AuthContainer from "../../components/AuthContainer/AuthContainer";
import { authActions, cssClasses, requests } from "../../constants";
import instance from "../../apis/axios";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";

const VerifyEmail = () => {
  const [queryParams] = useSearchParams();
  const token = queryParams.get("id");
  const navigate = useNavigate();
  const { auth } = cssClasses;
  const { authDispatch } = useContext(AuthContext);

  const verifyEmail = async (token) => {
    try {
      const response = await instance.post(requests.verifyEmail, { token });
      if (response.data?.accessToken) {
        authDispatch({
          type: authActions.LOGIN,
          payload: response.data,
        });
        return navigate("/home");
      } else {
        notify(response.data?.message || "Something went wrong");
        navigate("/login");
      }
    } catch (err) {
      notify(err.data?.message || "Something went wrong");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (!token) navigate("/login");
  }, [token, navigate]);

  useEffect(() => {
    const userData = JSON.parse(sessionStorage.getItem("userAuth"));
    if (userData) {
      navigate("/login");
    }
  }, [navigate]);

  return (
    <AuthContainer>
      <div className={auth.formTitleLeft}>
        <Typography
          variant="h5"
          sx={{ mt: 3 }}
          color={(theme) => theme.palette.secondary.main}
        >
          Click the button to verify your email and login
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3 }}
          onClick={() => {
            if (token) verifyEmail(token);
          }}
        >
          Verify & Login
        </Button>
      </div>
    </AuthContainer>
  );
};

export default VerifyEmail;
