export const routesForFooter = {
  "localhost:3000": {
    fullEntityName: "Acorn Insurance and Financial Services Ltd",
    fullEntityDetail:
      " Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "localhost:3001": {
    fullEntityName: "Acorn Insurance and Financial Services Ltd",
    fullEntityDetail:
      " Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.granitefinance.co.uk": {
    fullEntityName: "Granite Finance Services Ltd",
    fullEntityDetail:
      "payments.granitefinance.co.uk Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.acorninsure.com": {
    fullEntityName: "Acorn Insurance and Financial Services Ltd",
    fullEntityDetail:
      "Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.carrotinsurance.com": {
    fullEntityName: "Carrot Insurance Services Ltd",
    fullEntityDetail:
      "payments.carrotinsurance.com Company Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.flaginsurance.co.uk": {
    fullEntityName: "Flag Insurance Services Ltd",
    fullEntityDetail:
      "Company Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.haven.gi": {
    fullEntityName: "Payments Haven",
    fullEntityDetail:
      "Company Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },

  "payments.motorcade.co.uk": {
    fullEntityName: "Payments motorcade",
    fullEntityDetail:
      "Company Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
  "payments.rapidvm.co.uk": {
    fullEntityName: "Payments rapidvm",
    fullEntityDetail:
      "Company Information Acorn Insurance and Financial Services Ltd is authorised and regulated by the Financial Conduct Authority (FCA) Registration number 311873.",
    registeredAddress:
      "98 Liverpool Road, Formby, Liverpool, Merseyside, L37 6BS, No. 01636368",
    statement: "Modern Slavery Statement YE2022",
    statementLink:
      "https://www.acorninsure.co.uk/wp-content/uploads/2023/04/GGMSAStatementYE2022.pdf",
    genderPay: "Gender Pay Gap Reporting ",
    genderPayLink: "https://www.acorninsure.co.uk/gender-pay-gap-reporting/",
    contactUs: "01704 339 340",
    contactUsDetail: "- We are open Monday – Friday 9am – 5pm",
    contactUsEmail: "mailto:CreditControlTeam@AcornInsure.com",
    privacyPolicy: "Privacy Policy",
    privacyPolicyLink: "https://www.acorninsure.co.uk/docs/Privacy-Policy.pdf",
  },
};
