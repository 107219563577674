import React, { useCallback, useEffect } from "react";
import { routesForLogos } from "../../utils/routesForLogos";
import { Link, useNavigate } from "react-router-dom";

const Logo = ({ className, alt, style }) => {
  const [currentLogo, setcurrentLogo] = React.useState(null);
  const navigate = useNavigate();
  const currentURL = window.location.host;
  const setCurrentLogo = useCallback(() => {
    let foundLogo = routesForLogos[currentURL];
    if (foundLogo) {
      setcurrentLogo(foundLogo.logo);
    }
  }, [currentURL]);

  useEffect(() => {
    if (currentURL) setCurrentLogo();
  }, [currentURL, setCurrentLogo]);

  const callHomePage = () => {
    navigate("/home");
  };

  return (
    <div>
      <img
        className={className}
        alt={alt || "header logo"}
        src={currentLogo}
        style={{ ...style }}
        onClick={callHomePage}
      />
    </div>
  );
};

export default Logo;
