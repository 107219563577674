import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Slider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

import "../Dashboard/Dashboard.css";
import { requests } from "../../constants";
import { CustomerDetailsContext } from "../../stores/CustomerDetailsContext/CustomerDetailsContext";
import axios from "axios";
import { routesForLogos } from "../../utils/routesForLogos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment/moment";
import {
  ORDERED_PAYMENT_FREQUENCY,
  // countPaymenPlans,
  countPaymenPlansNew,
  getFrequencyWord,
} from "../../utils/countPaymentPlans";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { notify } from "../../components/ToastContainer/ToastContainer";
import PayerConfirmationDialog from "../../components/PayerConfirmationDialog/PayerConfirmationDialog";
import { getUserToken } from "../../utils/userToken";

const PaymentPlanChoice = () => {
  const { debtorDetailsState } = useContext(CustomerDetailsContext);
  const { authState } = useContext(AuthContext);
  const theme = useTheme();
  const { state } = useLocation();

  const amount = state?.amount || 100;
  var maxDate = new Date();
  maxDate.setDate(maxDate.getDate() + 30);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openSummaryDialog, setOpenSummaryDialog] = useState(false);
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [openPayerConfirmationDialog, setOpenPayerConfirmationDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [paymentPlan, setPaymentPlan] = React.useState([]);
  const [invoiceTerms, setInvoiceTerms] = React.useState([]);
  const [{ min, max }, setPaymentPlanTerms] = React.useState({
    min: 2,
    max: 5,
  });

  const location = useLocation();

  const validationSchema = yup.object({
    planFrequency: yup
      .string("Please select a plan frequency")
      .required("Please select a plan frequency"),
    months: yup
      .string("Please select a months")
      .required("Please select a months"),
    planStartDate: yup.date().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      planFrequency: invoiceTerms[0]?.Name,
      months: max,
      planStartDate: new Date(),
      method: "",
    },
    validationSchema: validationSchema,

    onSubmit: (values) => {
      axios
        .post(requests.CreateOpayoTakePaymentHostedFields, {
          pRequest: {
            InvoiceCode: location?.state?.transactionCode,
            PaymentAmount: Number(paymentPlan?.[0]?.amount),

            RedirectURL: `${routesForLogos.getCurrentURLConfig(
              "redirectURL"
            )}?invoiceCode=${newInvoiceCode}&months=${
              formik.values.months
            }&date=${decodeDate}&amount=${amount}&termPeriod=${
              choosenFrequency?.DTGroupInvoiceTermsList[0]?.TermPeriod
            }&fromPaymentPalnChoice=${true}&`,

            GatewayProviderMerchantId:
              routesForLogos.getCurrentURLConfig("merchantId"),
            Currency: "GBP",
            Code: authState?.user?.referenceId,
            Mode: "Debtor",
            Recurring: true,
            FirstPaymentDate:
              moment(values.planStartDate).format("DD-MM-YYYY") ||
              moment().format("DD-MM-YYYY"),
          },
          accessToken: getUserToken(),
        })
        .then((response) => {
          if (response?.data?.d?.OpayoNextUrl) {
            window.location.href = response?.data?.d?.OpayoNextUrl;
          }
        })
        .catch((err) =>
          notify(
            err?.response?.data?.d?.EnquiryResult?.Message ||
              "Unable to process recurring payment, please try again."
          )
        );
    },
  });

  const newInvoiceCode = location?.state?.transactionCode;
  const decodeDate = formik.values.planStartDate.valueOf();

  const choosenFrequency = invoiceTerms?.find(
    (item) => item?.Name === formik?.values?.planFrequency
  );

  useEffect(() => {
    if (!location?.state) return navigate("/financial-details");
  }, [location, navigate]);

  useEffect(() => {
    const fetchDTGroupInvoiceTerms = () => {
      setLoading(true);
      axios
        .post(requests.GetDTGroupInvoiceTerms, {
          pRequest: {
            DebtorTransactionCode: location?.state?.transactionCode,
          },
          accessToken: getUserToken(),
        })
        .then(async (response) => {
          if (response?.data?.d?.DTGroupInvoiceTermsGroupsList?.length) {
            const invoiceTermsList = [
              ...response?.data?.d?.DTGroupInvoiceTermsGroupsList,
            ];

            const getCustomInvoiceTermsList = async (invoiceTermsList) => {
              const ordering = {};

              Object.keys(ORDERED_PAYMENT_FREQUENCY).forEach(
                (element, index) => (ordering[element] = index)
              );

              const newInvoiceTermsList = await JSON.parse(
                JSON.stringify(invoiceTermsList)
                  .replaceAll("2-Weekly", "Fortnightly")
                  .replaceAll("4-Weekly", "4 Weekly")
              );

              const sortedNewInvoiceTermsList = newInvoiceTermsList.sort(
                (a, b) =>
                  ordering[a.DTGroupInvoiceTermsList[0].TermPeriod] -
                  ordering[b.DTGroupInvoiceTermsList[0].TermPeriod]
              );

              return sortedNewInvoiceTermsList;
            };

            const newInvoiceTermsList = await getCustomInvoiceTermsList(
              invoiceTermsList
            );

            setInvoiceTerms(newInvoiceTermsList);
            formik.setFieldValue("planFrequency", newInvoiceTermsList[0]?.Name);

            const months = Math.round(
              100 /
                newInvoiceTermsList[0]?.DTGroupInvoiceTermsList[0]?.Percentage
            );
            formik.setFieldValue("months", months);
            setPaymentPlanTerms({
              min: 2,
              max: months,
            });
            return setLoading(false);
          }
          if (response?.data?.d?.EnquiryResult?.SuccessCode !== 0)
            notify(
              response?.data?.d?.EnquiryResult?.Message ||
                "Unable to process payment, please try again."
            );
        })
        .catch((err) => {
          console.log(err);
          notify(
            err?.response?.data?.d?.EnquiryResult?.Message || err.toString()
          );
          return setLoading(false);
        })
        .finally(() => setLoading(false));
    };
    fetchDTGroupInvoiceTerms();
  }, [location, navigate]);

  useEffect(() => {
    const choosenFrequency = invoiceTerms?.find(
      (item) => item?.Name === formik?.values?.planFrequency
    );

    // * use countPaymenPlans function for older calculation
    // * countPaymenPlansNew is based on the new logic discussed in the spreadsheet "Granite Payment Calculation.xlsx"
    const { paymentPlans } = countPaymenPlansNew(
      amount,
      choosenFrequency?.DTGroupInvoiceTermsList[0]?.TermPeriod,
      formik.values.months,
      formik.values.planStartDate
    );

    if (!paymentPlans.length) return;

    setPaymentPlanTerms({
      min: 2,
      max: Math.round(
        100 / choosenFrequency?.DTGroupInvoiceTermsList[0]?.Percentage
      ),
    });
    setPaymentPlan(paymentPlans);
  }, [
    formik.values.amount,
    formik.values.planFrequency,
    formik.values.months,
    formik.values.planStartDate,
    debtorDetailsState.TotalDebt,
    amount,
    invoiceTerms,
  ]);

  const getSummary = () => {
    const firstInstalmentAmount = paymentPlan?.[0]?.amount;
    const instalments = paymentPlan?.length - 2; // Won't consider First and Last installment
    const instalmentAmount = paymentPlan?.[1]?.amount;
    const instalmentFrequency = getFrequencyWord(
      invoiceTerms?.find((term) => term.Name === formik?.values?.planFrequency)
        ?.DTGroupInvoiceTermsList[0]?.TermPeriod
    );
    const lastInstalmentAmount = paymentPlan?.[paymentPlan?.length - 1]?.amount;
    const lastInstalmentDate = moment(
      paymentPlan?.[paymentPlan?.length - 1]?.date
    ).format("Do MMMM YYYY");
    return (
      <Typography variant="body1">
        {`Initial payment of £${firstInstalmentAmount} today,${
          instalments
            ? ` followed by ${instalments} ${
                instalments === 1 ? "payment" : "payments"
              } of £${instalmentAmount} every 
                  ${instalmentFrequency}`
            : ""
        } and a final payment of £${lastInstalmentAmount} due on ${lastInstalmentDate}.`}
      </Typography>
    );
  };

  return (
    <>
      {!loading && paymentPlan?.length && invoiceTerms.length ? (
        <Box
          sx={{
            display: "block",
            flexDirection: "column",
            width: { xl: "50%", lg: "50%", xs: "100%" },
            minWidth: { xl: "50%", lg: "50%", xs: "auto" },
            padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
            overflow: { xl: "unset", lg: "unset", xs: "auto" },
            mt: { xs: 0, lg: 2, xl: 2 },
            flexWrap: "wrap",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
          position="relative"
        >
          <Typography
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon fontSize="large" />
          </Typography>
          <Box className="dialog-box" variant="contained">
            <Typography sx={{ mt: 1 }}>
              Should you be unable to find a suitable payment plan option and
              would like to discuss your options further, please contact our
              team on 01704 339 340. We are open Monday - Friday 9am - 5pm.
            </Typography>
          </Box>
          <Typography
            variant="h5"
            fontWeight={600}
            color={(theme) => theme.palette.primary.main}
            sx={{ textTransform: "uppercase" }}
          >
            Choose your Payment Plan
          </Typography>
          <Typography sx={{ mt: 1 }} variant="h6">
            Current Balance: £{Number(amount).toFixed(2)}
          </Typography>

          <form
            onSubmit={formik.handleSubmit}
            style={{ width: "100%", marginTop: "20px", paddingBottom: "15px" }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography>Payment Plan Frequency</Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                sx={{ width: { xl: "62%", lg: "62%", xs: "auto" } }}
              >
                <FormControl>
                  <RadioGroup
                    aria-labelledby="payment-plan-freq"
                    name="planFrequency"
                    label="Payment Plan Frequency"
                    onChange={(e) => {
                      formik.setFieldValue(
                        "months",
                        Math.round(
                          100 /
                            invoiceTerms?.find(
                              (item) => item?.Name === e.target.value
                            )?.DTGroupInvoiceTermsList[0]?.Percentage
                        )
                      );
                      return formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.planFrequency}
                    row
                    defaultValue={
                      invoiceTerms[0]?.DTGroupInvoiceTermsList[0]?.TermPeriod
                    }
                  >
                    {invoiceTerms.map((terms, i) => (
                      <FormControlLabel
                        value={terms?.Name}
                        control={<Radio />}
                        label={
                          terms?.DTGroupInvoiceTermsList[0]?.TermPeriod ||
                          terms?.Name
                        }
                        name="planFrequency"
                        key={terms?.Name}
                      />
                    ))}
                  </RadioGroup>
                  <FormHelperText
                    htmlFor="planFrequency"
                    error={!!formik.errors.planFrequency}
                  >
                    {(formik.touched.planFrequency &&
                      formik.errors.planFrequency) ||
                      " "}
                  </FormHelperText>
                </FormControl>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Typography>Payment Plan Term</Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                sx={{ width: { xl: "61%", lg: "61%", xs: "100%" } }}
              >
                <Slider
                  id="months"
                  name="months"
                  value={formik.values.months}
                  defaultValue={formik.values.months}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  marks={[
                    { value: min, label: min },
                    { value: max, label: max },
                  ]}
                  step={1}
                  min={min}
                  max={max}
                  valueLabelDisplay={
                    formik.values.months === min || formik.values.months === max
                      ? "auto"
                      : "on"
                  }
                />
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
              sx={{ mt: 1 }}
            >
              <Typography>Payment Plan Start Date</Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                sx={{ width: { xl: "61%", lg: "61%", xs: "auto" } }}
              >
                <LocalizationProvider dateAdapter={DateFnsUtils}>
                  <DatePicker
                    open={isForcePickerOpen}
                    onClose={() => setIsOpen(false)}
                    value={formik.values.planStartDate}
                    minDate={new Date()}
                    maxDate={maxDate}
                    onChange={(e) => {
                      return formik.setFieldValue("planStartDate", e);
                    }}
                    onBlur={formik.handleBlur}
                    PopperProps={{
                      placement: "bottom-end",
                      anchorEl: anchorEl,
                    }}
                    renderInput={({
                      inputRef,
                      InputProps,
                      disabled,
                      onChange,
                      value,
                      ...other
                    }) => (
                      <div ref={inputRef} {...other}>
                        <input
                          style={{ display: "none" }}
                          value={value}
                          onChange={onChange}
                          disabled={disabled}
                          // {...inputProps}
                          {...InputProps}
                        />
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={(event) => {
                            setIsOpen((isOpen) => !isOpen);
                            setAnchorEl(event.currentTarget);
                          }}
                        >
                          <CalendarMonthIcon />
                        </Button>
                      </div>
                    )}
                  />
                  <FormHelperText
                    htmlFor="planStartDate"
                    error={!!formik.errors.planStartDate}
                  >
                    {(formik.touched.planStartDate &&
                      formik.errors.planStartDate) ||
                      " "}
                  </FormHelperText>
                  <Typography sx={{ ml: 2 }}>
                    {formik?.values?.planStartDate &&
                      moment(formik?.values?.planStartDate).format(
                        "Do MMMM YYYY"
                      )}
                  </Typography>
                </LocalizationProvider>
              </Box>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              flexWrap="wrap"
              sx={{ mt: 3 }}
            >
              <Typography>Payment Plan Summary</Typography>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                flexWrap="wrap"
                sx={{ width: { xl: "61%", lg: "61%", xs: "auto" } }}
              >
                {getSummary()}
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              sx={{ mt: 3 }}
            >
              <Box
                sx={{
                  overflowX: "auto",
                  mt: 2,
                }}
                // display="block"
                padding="2px"
              >
                {debtorDetailsState?.TotalDebt && (
                  <table className="table">
                    <thead>
                      <tr style={{ background: theme.palette.primary.main }}>
                        {/* <th>Instalment</th> */}
                        <th>Instalment Due Date</th>
                        <th>Instalment Amount</th>
                        <th>Remaining Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentPlan?.map((data, index) => {
                        return (
                          <tr key={index}>
                            {/* <td>{index + 1}.</td> */}
                            <td>{moment(data?.date).format("Do MMMM YYYY")}</td>

                            <td>
                              £
                              {Number(data?.amount)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td>
                              £
                              {Number(data?.balance)
                                .toFixed(2)
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Button
                // type="submit"
                onClick={() => setOpenSummaryDialog(true)}
                className="form__button"
                variant="contained"
                sx={{ width: "40%" }}
                // fullWidth
              >
                Proceed
              </Button>
            </Box>
            <Dialog
              open={openSummaryDialog}
              onCloseUpdateCardDialog={() => setOpenSummaryDialog(false)}
            >
              <DialogTitle>Confirm Payment Plan</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  <strong>Total Payment Plan amount: </strong> £
                  {Number(amount).toFixed(2)}
                  <br />
                  <br />
                  {getSummary()}
                  <br />
                  <p>
                    {" "}
                    <strong>By proceeding, you are confirming: </strong>
                    <br />
                    ⦁ There are no circumstances you are aware of which may
                    impact your ability to repay your monthly instalments for
                    the duration of your payment plan. <br />
                    ⦁ You are able to pay the proposed payment plan alongside
                    your essential monthly bills and expenses ensuring no
                    priority bills are adversely affected. <br />⦁ You are happy
                    for all future payments to be collected via Continuous
                    Payment Authority (CPA) using the card details provided.
                  </p>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpenSummaryDialog(false)}>
                  Cancel
                </Button>
                <Button
                  // type="submit"
                  onClick={() => setOpenPayerConfirmationDialog(true)}
                >
                  Proceed
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xl: "50%", lg: "50%", xs: "100%" },
            minWidth: { xl: "50%", lg: "50%", xs: "auto" },
            padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
            overflow: { xl: "unset", lg: "unset", xs: "auto" },
            mt: { xs: 0, lg: 2, xl: 2 },
            flexWrap: "wrap",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon fontSize="large" />
          </Typography>
          <Typography
            variant="h5"
            fontWeight={600}
            color={(theme) => theme.palette.primary.main}
          >
            PAYMENT PLAN CHOICE
          </Typography>
          <Typography sx={{ mt: 1 }} variant="h6">
            CURRENT BALANCE: £{Number(amount).toFixed(2)}
          </Typography>
          <Stack
            direction="row"
            flex="1"
            justifyContent={"center"}
            alignItems="center"
            alignSelf="center"
          >
            {!loading && !invoiceTerms.length ? (
              <Typography>No payment plans available for this debt</Typography>
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Box>
      )}

      <PayerConfirmationDialog
        isOpen={openPayerConfirmationDialog}
        hanldeOnClick={() => {
          setOpenPayerConfirmationDialog(false);
          formik.handleSubmit();
        }}
        handleOnClick3rdPaty={() => {
          setOpenSummaryDialog(false);

          // callNewFunc();

          return navigate("/checkout-details", {
            state: {
              ...location?.state,
              formValues: formik.values,
              // paymentPlan,
              isPaymentPlanSelected: true,
              fromDebtDetailCard: true,
              redirectURL: `${routesForLogos.getCurrentURLConfig(
                "redirectURL"
              )}?invoiceCode=${newInvoiceCode}&months=${
                formik.values.months
              }&date=${decodeDate}&amount=${amount}&termPeriod=${
                choosenFrequency?.DTGroupInvoiceTermsList[0]?.TermPeriod
              }&fromPaymentPalnChoice=${true}&`,
              invoiceCode: `${newInvoiceCode}`,
              PaymentAmount: Number(paymentPlan?.[0]?.amount),
            },
          });
        }}
        handleOnClickCanel={() => {
          setOpenPayerConfirmationDialog(false);
        }}
      />
    </>
  );
};

export default PaymentPlanChoice;
