import { Box, Button, Typography } from "@mui/material";
import React from "react";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

import "../Dashboard/Dashboard.css";

const FailPayment = () => {
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        display: "block",
        flexDirection: "column",
        width: { xl: "50%", lg: "50%", xs: "100%" },
        minWidth: { xl: "50%", lg: "50%", xs: "auto" },
        padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
        overflow: { xl: "unset", lg: "unset", xs: "auto" },
        mt: { xs: 0, xl: 2, lg: 2 },
        flexWrap: "wrap",
      }}
      justifyContent="flex-start"
      alignItems="flex-start"
    >
      <Typography
        color={(theme) => theme.palette.primary.main}
        onClick={() => navigate("/financial-details")}
      >
        <ArrowBackIcon fontSize="large" />
      </Typography>
      <Typography
        variant="h5"
        fontWeight={600}
        color={(theme) => theme.palette.primary.main}
      >
        Payment Status
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        width="80%"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ mt: 5 }}
      >
        <div>
          <CancelIcon
            color="error"
            sx={{
              height: 90,
              width: 90,
            }}
          />
        </div>
        <Box sx={{ ml: 2 }}>
          <Typography fontSize={20} fontWeight={600}>
            Payment Failed
          </Typography>
          <Typography
            fontSize={13}
            color={(theme) => theme.palette.secondary.main}
          >
            We can't process your payment. Please check your internet connection
            and try again.
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          mt: 3,
          width: { xs: "100%", sm: "50%" },
        }}
      >
        <Link style={{ display: "flex" }} to="/financial-details">
          <Button
            className="form__button"
            variant="contained"
            sx={{
              flex: "1",
            }}
          >
            Financial Details
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default FailPayment;
