import moment from "moment/moment";

export const ORDERED_PAYMENT_FREQUENCY = {
  Weekly: 7,
  Fortnightly: 14,
  "4 Weekly": 28,
  Monthly: 30,
};

const convertToDays = (str = "") => {
  const days = ORDERED_PAYMENT_FREQUENCY;

  if (str?.indexOf("-") !== -1) {
    return Number(str?.split("-")[0]) * days?.[str?.split("-")[1]];
  }
  return days?.[str];
};
export const countPaymenPlans = (amount, frequency, planTerm, startDate) => {
  var paymentPlans = [];
  var initalBalance = amount;
  const daysCount = convertToDays(frequency);
  if (
    startDate !== null &&
    moment(new Date(startDate)).format("DD-MM-YYYY") !==
      moment(new Date()).format("DD-MM-YYYY")
  ) {
    initalBalance -= 0.01;

    let TotalDebtInPence = initalBalance * 100,
      NumberOfPayments = planTerm;

    let TotalDebtRemainder = (
      ((TotalDebtInPence % NumberOfPayments) + NumberOfPayments) %
      NumberOfPayments
    ).toFixed(2);

    let LastPayment;

    let PaymentAmountInPence = (
      (TotalDebtInPence - TotalDebtRemainder) /
      NumberOfPayments
    ).toFixed(2);

    PaymentAmountInPence /= 100;
    TotalDebtRemainder /= 100;

    if (TotalDebtRemainder > 0) {
      LastPayment = (PaymentAmountInPence + TotalDebtRemainder).toFixed(2);
    }

    paymentPlans = [
      {
        date: new Date(),
        amount: 0.01,
        balance: initalBalance,
      },
      ...paymentPlans,
    ];
    for (let index = 1; index <= planTerm; index++) {
      var date = index > 1 && new Date(paymentPlans[index - 1]?.date);

      initalBalance =
        index === planTerm
          ? LastPayment
            ? Number(initalBalance).toFixed(2) - Number(LastPayment)
            : Number(initalBalance).toFixed(2) - PaymentAmountInPence
          : Number(initalBalance).toFixed(2) - PaymentAmountInPence;

      if (index === 1) {
        paymentPlans.push({
          date: startDate || new Date(),
          amount: PaymentAmountInPence,
          balance: initalBalance.toFixed(2),
        });
      } else {
        date.setDate(date.getDate() + daysCount);
        paymentPlans.push({
          date: date,
          amount:
            index === planTerm
              ? LastPayment || PaymentAmountInPence
              : PaymentAmountInPence,
          balance: initalBalance.toFixed(2),
        });
      }
    }
  } else {
    let TotalDebtInPence = amount * 100,
      NumberOfPayments = planTerm;

    let TotalDebtRemainder = (
      ((TotalDebtInPence % NumberOfPayments) + NumberOfPayments) %
      NumberOfPayments
    ).toFixed(2);

    let LastPayment;

    let PaymentAmountInPence = (
      (TotalDebtInPence - TotalDebtRemainder) /
      NumberOfPayments
    ).toFixed(2);

    PaymentAmountInPence /= 100;
    TotalDebtRemainder /= 100;

    if (TotalDebtRemainder > 0) {
      LastPayment = (PaymentAmountInPence + TotalDebtRemainder).toFixed(2);
    }

    for (let index = 0; index < planTerm; index++) {
      var newDate = index > 0 && new Date(paymentPlans[index - 1]?.date);

      initalBalance =
        index === planTerm - 1
          ? LastPayment
            ? Number(initalBalance).toFixed(2) - Number(LastPayment)
            : Number(initalBalance).toFixed(2) - PaymentAmountInPence
          : Number(initalBalance).toFixed(2) - PaymentAmountInPence;

      if (index === 0) {
        paymentPlans.push({
          date: startDate || new Date(),
          amount: PaymentAmountInPence,
          balance: initalBalance.toFixed(2),
        });
      } else {
        newDate.setDate(newDate.getDate() + daysCount);
        paymentPlans.push({
          date: newDate,
          amount:
            index === planTerm - 1
              ? LastPayment || PaymentAmountInPence
              : PaymentAmountInPence,
          balance: initalBalance.toFixed(2),
        });
      }
    }
  }

  return {
    paymentPlans: paymentPlans
      .map((i) => ({
        ...i,
        amount: Number.isInteger(i?.amount) ? i?.amount.toFixed(2) : i?.amount,
        balance: Number.isInteger(i?.balance)
          ? i?.balance.toFixed(2)
          : i?.balance,
      }))
      .map((i) => ({
        ...i,
        amount:
          i?.amount.toString().split(".")[1].length === 1
            ? Number(i?.amount).toFixed(2)
            : i?.amount,
        balance:
          i?.balance.toString().split(".")[1].length === 1
            ? Number(i?.balance).toFixed(2)
            : i?.balance,
      })),
  };
};

// * countPaymenPlansNew is based on the new logic discussed in the spreadsheet "Granite Payment Calculation.xlsx"
export const countPaymenPlansNew = (amount, planTerm, terms, startDate) => {
  const totalAmount = +amount;
  if (!totalAmount || !planTerm || !terms || !startDate)
    return { paymentPlans: [] };

  // Get the days based on the plan selected. To be used for calculating future payment dates.
  // * If the platnTerm is set to Monthly then no need to calculate days as we can add month instead of days in the final payment table
  const isMonthlyPaymentSelected = planTerm === "Monthly";
  const daysCount = isMonthlyPaymentSelected ? 1 : convertToDays(planTerm);

  let paymentAmounts = [];
  const isSinglePennyPaymentIncluded =
    startDate !== null &&
    moment(new Date(startDate)).format("DD-MM-YYYY") !==
      moment(new Date()).format("DD-MM-YYYY");
  const actualTotalAmountInPounds = isSinglePennyPaymentIncluded
    ? totalAmount - 0.01
    : totalAmount;
  const actualTotalAmountInPennies = actualTotalAmountInPounds * 100;
  const remainder = actualTotalAmountInPennies % terms;
  const netTotal = actualTotalAmountInPennies - remainder;

  const netPeriodPayment = isSinglePennyPaymentIncluded
    ? (netTotal / terms + 1) / 100
    : remainder === 0
    ? netTotal / terms / 100
    : (netTotal / terms + 1) / 100;

  if (isSinglePennyPaymentIncluded) {
    paymentAmounts.push(0.01); //* First Payment
  }

  paymentAmounts = [
    ...paymentAmounts,
    ...Array.from(Array(terms - 1).keys()).map((i) => netPeriodPayment),
  ];

  // * Last Payment Instalment calculation.
  const totalAmountPaidBeforeLastPayment = isSinglePennyPaymentIncluded
    ? netPeriodPayment * (terms - 1) + 0.01
    : netPeriodPayment * (terms - 1);
  paymentAmounts.push(
    +Number(totalAmount - totalAmountPaidBeforeLastPayment).toFixed(2)
  );

  // * Dates & Remaining balance generation for final result
  let balanceAmount = totalAmount; // Tracker for getting the remaining balance.
  const finalPaymentResult = paymentAmounts.map((amount, index) => {
    const balance = Number(balanceAmount - amount).toFixed(2);
    balanceAmount = Number(balanceAmount - amount).toFixed(2);

    const getNextPaymentDate = () => {
      // If there's a single penny payment included then return current date for the first date.
      if (isSinglePennyPaymentIncluded && index === 0) {
        return moment(new Date()).toString();
      }

      // If there's a single penny payment included then there's no need to add the unit (days / months) to the StartDate
      // as the StartDate will be a second payment date.
      const newIndex = isSinglePennyPaymentIncluded ? index - 1 : index;
      const unit = isMonthlyPaymentSelected ? "months" : "days";

      const date = moment(new Date(startDate));
      const nextDate = moment(date).add(daysCount * newIndex, unit);

      // For monthly payent option, check if the dates are same or not.
      // This is a special case for the dates after 28. If the dates are not the same then the next payment date fall to the next available date.
      // For example, if the StartDate is 29th Jan, the next date should be 29th Feb but if it is not a leap year then the next payment date will be 1st Mar.
      if (isMonthlyPaymentSelected) {
        return date.get("D") === nextDate.get("D")
          ? nextDate.toString()
          : nextDate.add(1, "days").toString();
      }

      return nextDate.toString();
    };

    return {
      amount: amount.toFixed(2),
      date: getNextPaymentDate(),
      balance,
    };
  });

  return { paymentPlans: finalPaymentResult };
};

const FREQUENCY_MAPPING_FOR_SUMMARY = {
  Weekly: "week",
  Fortnightly: "fortnight",
  Monthly: "month",
};

export const getFrequencyWord = (str = "") => {
  const days = FREQUENCY_MAPPING_FOR_SUMMARY;

  if (str?.indexOf(" ") !== -1) {
    const [occurancy, frequency] = str?.split(" ");
    return `${occurancy} ${days?.[frequency]}${occurancy > 0 ? "s" : ""}`;
  }
  return days?.[str];
};
