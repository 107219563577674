import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useCallback, useContext, useEffect, useState } from "react";

import BalanceCard from "../../components/BalanceCard/BalanceCard";

import { debtorDetailsActions, debtStatus, requests } from "../../constants";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { CustomerDetailsContext } from "../../stores/CustomerDetailsContext/CustomerDetailsContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";

import "../Dashboard/Dashboard.css";
import findTotalDebt from "../../utils/findTotalDebt";
import moment from "moment";
import { getUserToken } from "../../utils/userToken";
// import fetchDTInvoiceTerms from "../../apis/fetchDTInvoiceTerms";
import { notify } from "../../components/ToastContainer/ToastContainer";

const FinancialDetails = () => {
  const navigate = useNavigate();
  const { debtorDetailsState, debtorDetailsDispatch } = useContext(
    CustomerDetailsContext
  );
  const { authState } = useContext(AuthContext);
  const [loading, setloading] = useState(false);
  const theme = useTheme();
  const [transctionInfo, setTransctionInfo] = useState({});

  const getDTFunnc = useCallback((CodeID) => {
    return axios.post(requests.GetDTInvoiceTerms, {
      accessToken: getUserToken(),
      DebtorTransactionCode: CodeID,
    });
  }, []);

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      setloading(true);
      if (authState?.user?.referenceId) {
        try {
          const debtorDetails = await axios.post(requests.GetDebtor, {
            accessToken: getUserToken(),
            DebtorReference: authState?.user?.referenceId,
          });
          if (debtorDetails?.data?.d?.EnquiryResult?.SuccessCode === 0) {
            const transactionsDetails = await axios.post(
              requests.GetDebtorsTransactionListJSON,
              {
                accessToken: getUserToken(),
                Status: "",
                SearchType: "DebtorId",
                SearchText: debtorDetails?.data?.d?.Debtor?.DebtorCode,
                startDate: "",
                endDate: "",
                recordStartNo: 1,
                recordEndNo: 10000,
              }
            );
            if (
              transactionsDetails?.data?.d?.EnquiryResult?.SuccessCode === 0
            ) {
              debtorDetailsDispatch({
                type: debtorDetailsActions.ADDDETAILS,
                payload: {
                  ...debtorDetails?.data?.d?.Debtor,
                  Transactions: transactionsDetails.data.d.Records,
                  TotalDebt: findTotalDebt(transactionsDetails.data.d.Records),
                },
              });

              return setloading(false);
              // fetchDTInvoiceTerms(transactionsDetails?.data?.d?.Records)
              //   .then((response) => {
              //     debtorDetailsDispatch({
              //       type: debtorDetailsActions.ADDTRANSACTIONTERMS,
              //       payload: {
              //         TransactionsTerms: response,
              //       },
              //     });
              //     return setloading(false);
              //   })
              //   .catch((err) => {
              //     setloading(false);
              //     return notify(
              //       err?.response?.data?.Message ||
              //         err?.response?.data?.message ||
              //         err
              //     );
              //   });
            }
          }
        } catch (err) {
          setloading(false);
          return notify(
            err?.response?.data?.Message || err?.response?.data?.message || err
          );
        }
      }
    };

    fetchCustomerDetails();
  }, [authState?.user?.referenceId, debtorDetailsDispatch]);

  useEffect(() => {
    if (!debtorDetailsState?.Transactions) return;
    let listTransAPI = debtorDetailsState?.Transactions?.map((data) => {
      return getDTFunnc(data?.Code);
    });

    Promise.allSettled(listTransAPI)
      .then((data) => {
        let info = {};
        data.forEach((list) => {
          let Code = JSON.parse(list.value.config.data).DebtorTransactionCode;
          info[Code] = list.value.data.d;
        });
        setTransctionInfo(info);
      })
      .catch((err) => {
        setTransctionInfo({});
        console.log(err);
      });
  }, [debtorDetailsState?.Transactions]);

  // console.log("debtorDetailsState", debtorDetailsState);

  return (
    <>
      {Object.keys(debtorDetailsState).length ? (
        <Box
          sx={{
            display: "block",
            flexDirection: "column",
            width: { xl: "50%", lg: "50%", xs: "100%" },
            minWidth: { xl: "50%", lg: "50%", xs: "auto" },
            padding: { xl: "0 25%", lg: "0 25%", xs: "15px" },
            overflow: { xl: "unset", lg: "unset", xs: "auto" },
            mt: { xs: 0, lg: 2, xl: 2 },
            flexWrap: "wrap",
          }}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Typography
            color={(theme) => theme.palette.primary.main}
            onClick={() => navigate("/home")}
          >
            <ArrowBackIcon fontSize="large" />
          </Typography>
          <Typography
            variant="h5"
            fontWeight={600}
            color={(theme) => theme.palette.primary.main}
          >
            Account Summary
          </Typography>
          <BalanceCard
            availableBalance={debtorDetailsState?.TotalDebt}
            debtorReference={debtorDetailsState?.DebtorCode}
            loading={loading}
          />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            padding={2}
            border={3}
            borderColor={(theme) => theme.palette.primary.main}
            sx={{ mt: 3, mb: 2 }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Account Breakdown</Typography>
              {loading && <CircularProgress size={10} />}
            </Box>
            {debtorDetailsState?.Transactions?.length ? (
              <Box
                sx={{
                  overflowX: "auto",
                  // maxHeight: { xs: "370px", lg: "264px", xl: "264px" },
                  // height: { xs: "370px", lg: "264px", xl: "264px" },
                }}
                // display="block"
                padding="2px"
              >
                <table className="table">
                  <thead>
                    <tr style={{ background: theme.palette.primary.main }}>
                      <th>Reference ID</th>
                      <th>Original Due Date </th>
                      <th>Original Balance</th>
                      <th>Current Balance </th>
                      <th>Status</th>
                      <th>Next Payment Date </th>
                    </tr>
                  </thead>
                  <tbody>
                    {debtorDetailsState?.Transactions?.map((data, index) => {
                      // let dataTmp = getTermsLength(data.Code);

                      let transctionDetail = transctionInfo[data.Code];
                      const firstActiveTerm = transctionDetail?.Terms.find(
                        (termData) => termData.TermAmountBalance > 0
                      );
                      const nextDueDate = firstActiveTerm
                        ? moment(
                            firstActiveTerm.TermDueDate,
                            "DD-MM-YYYY"
                          ).format("Do MMMM YYYY")
                        : "N/A";

                      return (
                        <tr key={index}>
                          <td>{data?.Code || index + 1}</td>
                          <td>
                            {moment(data?.InvoiceDate).format("Do MMMM YYYY")}
                          </td>
                          <td>
                            £
                            {Number(data?.TotalAmount)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            £
                            {Number(data?.InvoiceBalance)
                              .toFixed(2)
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td>
                            {transctionDetail && transctionDetail ? (
                              transctionDetail &&
                              transctionDetail.Terms &&
                              transctionDetail.Terms[0] ? (
                                transctionDetail.Terms.length >= 0 ? (
                                  <Link
                                    to="/debt-details"
                                    state={{
                                      startDate: data?.InvoiceDate,
                                      amount: data?.TotalAmount,
                                      terms:
                                        debtorDetailsState?.DebtorTransactions,
                                      requestType: "changeCard",
                                      code: data?.Code,
                                      invoiceBalance: data?.InvoiceBalance,
                                    }}
                                  >
                                    <Chip
                                      label="ACTIVE"
                                      color="info"
                                      variant="outlined"
                                    />
                                  </Link>
                                ) : data?.Status === debtStatus.PAID ? (
                                  <Link
                                    // to="/debt-details"
                                    state={{
                                      startDate: data?.InvoiceDate,
                                      amount: data?.TotalAmount,
                                      code: data?.Code,
                                      invoiceBalance: data?.InvoiceBalance,
                                      // terms:
                                      //   debtorDetailsState?.TransactionsTerms?.[
                                      //     data?.Code
                                      //   ],
                                    }}
                                  >
                                    <Chip label="FULLY PAID" color="success" />
                                  </Link>
                                ) : data?.Status === debtStatus.NOTPAID ? (
                                  <Link
                                    to="/make-payment"
                                    state={{
                                      transactionCode: data?.Code,
                                      amount: data?.TotalAmount,
                                    }}
                                  >
                                    <Button color="primary">
                                      Make Payment / Payment Plan
                                    </Button>
                                  </Link>
                                ) : (
                                  ""
                                )
                              ) : data?.Status === debtStatus.PAID ? (
                                <Link
                                  // to="/debt-details"
                                  state={{
                                    startDate: data?.InvoiceDate,
                                    amount: data?.TotalAmount,
                                    code: data?.Code,
                                    // terms:
                                    //   debtorDetailsState?.TransactionsTerms?.[
                                    //     data?.Code
                                    //   ],
                                  }}
                                >
                                  <Chip label="FULLY PAID" color="success" />
                                </Link>
                              ) : (
                                <Link
                                  to="/make-payment"
                                  state={{
                                    transactionCode: data?.Code,
                                    amount: data?.TotalAmount,
                                  }}
                                >
                                  <Button color="primary">
                                    Make Payment / Payment Plan
                                  </Button>
                                </Link>
                              )
                            ) : (
                              "Loading..."
                            )}
                          </td>
                          <td>
                            {transctionDetail?.Terms &&
                            transctionDetail.Terms[0]
                              ? nextDueDate
                              : "-"}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default FinancialDetails;
