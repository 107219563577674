import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requests } from "../../constants";

import { countPaymenPlansNew } from "../../utils/countPaymentPlans";
import moment from "moment/moment";
import { notify } from "../../components/ToastContainer/ToastContainer";
import { fetchInvoiceTerms } from "../../apis/fetchDTInvoiceTerms";
import { getUserToken } from "../../utils/userToken";

const ProcessingPayment = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  // Get the values from the query parameters
  const invoiceCode = searchParams.get("invoiceCode");
  const months = searchParams.get("months");
  const dateString = searchParams.get("date");
  const amount = searchParams.get("amount");
  const termPeriod = searchParams.get("termPeriod");
  const transactionCodeID = searchParams.get("TRId");
  const fromDebtDetailCard = searchParams.get("fromDebtDetailCard");
  const fromPaymentPalnChoice = searchParams.get("fromPaymentPalnChoice");
  const updateDebtAmount = searchParams.get("updateDebtAmount");

  // Decode the date value
  const startDate = new Date(parseInt(dateString));
  // const [terms, setTerms] = useState([]);

  const { paymentPlans } = countPaymenPlansNew(
    amount,
    termPeriod,
    months,
    startDate
  );

  useEffect(() => {
    axios
      .post(requests.GetTakePaymentTransaction, {
        TransactionCode: transactionCodeID,
        accessToken: getUserToken(),
      })
      .then(async (response) => {
        if (response?.data?.d?.TakePaymentTransaction?.Status === "OK") {
          if (fromPaymentPalnChoice) {
            axios
              .post(requests.CreateDTTermsCustom, {
                pRequest: {
                  DTCode: invoiceCode,
                  TermList: paymentPlans.map((d) => ({
                    Term: null,
                    TermPer: null,
                    Amount: d?.amount.toString(),
                    DueDate: moment(d?.date).format("DD-MMM-yyyy"),
                  })),
                },
                accessToken: getUserToken(),
              })
              .then((response) => {
                axios
                  .post(requests.UpdateDebtorTransaction, {
                    pRequest: {
                      DebtorTransactionCode: invoiceCode,
                      OrderNumber: "",
                      OriginalReference: "",
                      Details: "",
                      WorkflowStep: "3",
                    },
                    accessToken: getUserToken(),
                  })
                  .then((responseData) => {
                    if (
                      responseData?.data?.d?.EnquiryResult?.SuccessCode === 0
                    ) {
                      return navigate("/payment-plan-confirmation", {
                        state: {
                          paymentPlans,
                        },
                      });
                    }
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) =>
                notify(
                  err?.response?.data?.d?.EnquiryResult?.Message ||
                    "Unable to process recurring payment, please try again."
                )
              );
          } else if (fromDebtDetailCard) {
            axios
              .post(requests.CreateDTTermsCustom, {
                pRequest: {
                  DTCode: invoiceCode,
                  TermList: [
                    {
                      Term: null,
                      TermPer: null,
                      Amount: updateDebtAmount,
                      DueDate: moment().format("DD-MMM-yyyy"),
                    },
                  ],
                },
                accessToken: getUserToken(),
              })
              .then((response) => {
                axios
                  .post(requests.UpdateDebtorTransaction, {
                    pRequest: {
                      DebtorTransactionCode: invoiceCode,
                      OrderNumber: "",
                      OriginalReference: "",
                      Details: "",
                      WorkflowStep: "3",
                    },
                    accessToken: getUserToken(),
                  })
                  .then((responseData) => {
                    if (
                      responseData?.data?.d?.EnquiryResult?.SuccessCode === 0
                    ) {
                      return navigate("/payment-plan-confirmation", {
                        state: {
                          paymentPlans,
                        },
                      });
                    }
                  })
                  .catch((err) => console.log(err));
              })
              .catch((err) =>
                notify(
                  err?.response?.data?.d?.EnquiryResult?.Message ||
                    "Unable to process recurring payment, please try again."
                )
              );
          } else {
            axios
              .post(requests.UpdateDebtorTransaction, {
                pRequest: {
                  DebtorTransactionCode: invoiceCode,
                  OrderNumber: "",
                  OriginalReference: "",
                  Details: "",
                  WorkflowStep: "6",
                },
                accessToken: getUserToken(),
              })
              .then((responseData) => {
                if (responseData?.data?.d?.EnquiryResult?.SuccessCode === 0) {
                  return navigate("/payment-success");
                }
              })
              .catch((err) => console.log(err));
          }
        } else if (
          ["NOTAUTHED", "ABORT", "REJECTED", "ERROR"].includes(
            response?.data?.d?.TakePaymentTransaction?.Status
          )
        ) {
          return navigate("/payment-fail");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    navigate,
    paymentPlans,
    location?.state?.invoiceCode,
    location?.state?.transactionCode,
  ]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      sx={{
        width: { xl: "50%", xs: "100%" },
        minWidth: { xl: "50%", xs: "auto" },
        padding: { xl: "0 25%", xs: "15px" },
      }}
    >
      <CircularProgress />
      <Typography>Processing payment</Typography>
    </Box>
  );
};

export default ProcessingPayment;
