import {
  AppBar,
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";

import "./Header.css";
import { AuthContext } from "../../stores/AuthContext/AuthContext";
import { authActions, debtorDetailsActions } from "../../constants";
import { CustomerDetailsContext } from "../../stores/CustomerDetailsContext/CustomerDetailsContext";
import Logo from "../Logo/Logo";

const Header = () => {
  const navigate = useNavigate();
  const { authState, authDispatch } = useContext(AuthContext);
  const { debtorDetailsState, debtorDetailsDispatch } = useContext(
    CustomerDetailsContext
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar color="inherit" position="fixed" sx={{ zIndex: 2 }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          <Logo className="header__logo" alt="header logo" />
        </Typography>

        <Link
          style={{ display: "flex", justifyContent: "end" }}
          to="/need-additional-support"
        >
          <Button
            className="form__button"
            variant="contained"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Need Additional Support?
          </Button>
          <Button
            className="form__button"
            variant="contained"
            sx={{ display: { xs: "block", sm: "none" } }}
          >
            Support
          </Button>
        </Link>
        <Button
          onClick={handleClick}
          sx={{ ml: 2 }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          color="secondary"
          size="small"
          edge="start"
          aria-label="logout"
          className="form__button"
          style={{ minWidth: "fit-content" }}
        >
          <Avatar sx={{ width: 32, height: 32, mr: 1 }} />
          {debtorDetailsState?.Firstname
            ? (debtorDetailsState?.Title1 !== null
                ? debtorDetailsState?.Title1
                : "" || debtorDetailsState?.Title2 !== null
                ? debtorDetailsState?.Title2
                : "") +
              " " +
              debtorDetailsState?.Firstname +
              " " +
              debtorDetailsState?.Surname
            : authState?.user?.email.toUpperCase()}
        </Button>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem
            onClick={() => {
              authDispatch({ type: authActions.LOGOUT });
              debtorDetailsDispatch({
                type: debtorDetailsActions.REMOVEDETAILS,
              });
              navigate("/login");
            }}
          >
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
