import acornLogoLandscape from "../assets/logo/Acorn-logo-landscape.png";
import carrotInsuranceLogo from "../assets/logo/Carrot Insurance Logo.png";
import FIBLogo from "../assets/logo/FIB Logo.png";
import graniteFinanceLogo from "../assets/logo/Granite Finance Logo.jpg";
// import graniteGroupLogo from "../assets/logo/Granite-Group-Logo-Large.png";
import heavenLogo from "../assets/logo/Haven Logo.png";
// import ladiesFirstLogo from "../assets/logo/Ladies-First-Logo-Black.png";
import motorcadeLogo from "../assets/logo/Motorcade logo 2022 v2.png";
import rapidLogo from "../assets/logo/Rapid-Logo.png.jpg";

export const routesForLogos = {
  "localhost:3000": {
    logo: graniteFinanceLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "localhost:3001": {
    logo: graniteFinanceLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.granitefinance.co.uk": {
    logo: graniteFinanceLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.acorninsure.com": {
    logo: acornLogoLandscape,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.carrotinsurance.com": {
    logo: carrotInsuranceLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.flaginsurance.co.uk": {
    logo: FIBLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.haven.gi": {
    logo: heavenLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  // "localhost:3007": {
  //   logo: ladiesFirstLogo,
  //   merchantId: "Acorninsurancea",
  //   redirectURL:  window.location.origin + "/processing-payment",
  // },
  "payments.motorcade.co.uk": {
    logo: motorcadeLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  "payments.rapidvm.co.uk": {
    logo: rapidLogo,
    merchantId: "Acorninsurancea",
    redirectURL: window.location.origin + "/processing-payment",
    domain: window.location.origin,
  },
  getCurrentURLConfig: (propName) => {
    const currentURL = window.location.host;
    return routesForLogos?.[currentURL]?.[propName];
  },
};
