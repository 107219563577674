const { createTheme } = require("@mui/material");

const theme = createTheme({
  palette: {
    primary: {
      main: "#A58429",
    },
    secondary: {
      main: "#58585B",
    },
  },
  typography: {
    fontFamily: ["Lato"],
  },
});

export default theme;
