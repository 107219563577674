// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app {
  display: flex;
}
.app__content {
  width: 98vw;
}
.form__button {
  font-family: "Lato", sans-serif !important;
  /* font-size: large !important; */
  border-radius: 15px !important;
  height: 50px;
}

.gpay-button.black.pay.en {
  font-family: "Lato", sans-serif !important;
  font-size: large !important;
  border-radius: 15px !important;
  height: 50px;
  background-color: #a58429 !important;
}

.gpay-button.black.pay.en:hover {
  text-decoration: none;
  background-color: rgb(115, 92, 28) !important;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,WAAW;AACb;AACA;EACE,0CAA0C;EAC1C,iCAAiC;EACjC,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,0CAA0C;EAC1C,2BAA2B;EAC3B,8BAA8B;EAC9B,YAAY;EACZ,oCAAoC;AACtC;;AAEA;EACE,qBAAqB;EACrB,6CAA6C;EAC7C;kFACgF;AAClF","sourcesContent":[".app {\r\n  display: flex;\r\n}\r\n.app__content {\r\n  width: 98vw;\r\n}\r\n.form__button {\r\n  font-family: \"Lato\", sans-serif !important;\r\n  /* font-size: large !important; */\r\n  border-radius: 15px !important;\r\n  height: 50px;\r\n}\r\n\r\n.gpay-button.black.pay.en {\r\n  font-family: \"Lato\", sans-serif !important;\r\n  font-size: large !important;\r\n  border-radius: 15px !important;\r\n  height: 50px;\r\n  background-color: #a58429 !important;\r\n}\r\n\r\n.gpay-button.black.pay.en:hover {\r\n  text-decoration: none;\r\n  background-color: rgb(115, 92, 28) !important;\r\n  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),\r\n    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
